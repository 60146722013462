import React from 'react';
import {
  AboutPage,
  AboutContainer1,
  AboutHeading,
  InitialParagraph,
  AboutContainer2,
  AboutParagraph2,
  AboutParagraph3,
  AboutParagraph4,
  ImgStyle,
  ParagraphContainer,
  AboutContainer3,
  AboutTeamHeading,
  PageBackground,
  AboutContainer4,
  AboutParagraph5,
  LeftContainer,
  RightContainer,
  NameHeading,
  NoaImage,
  AboutNoa,
  AboutKai,
  AboutSub1,
  AboutSub2,
  SocialLogo1,
  SocialLogo2,
  SocialLocation,
  Social1Link1,
  Social1Link2,
  Social2Link1,
  Social2Link2
} from './aboutstyle'

const About = () => {
  return (
    <AboutPage>
      <PageBackground src={require('../../images/Background_Ful_Page.png')}/>
      <AboutContainer1>
        <AboutHeading>About Us</AboutHeading>
        <InitialParagraph>
          The Fishing industry plays a vital role in the global economy, 
          providing food and livelihood for millions of people around the world. However, this 
          industry faces many significant challenges related to sustainability, 
          traceability and the lack of transparency. In many areas the practice of illegal, 
          unreported and unregulated fishing is used, leading to overfishing.
        </InitialParagraph>
      </AboutContainer1>
      <AboutContainer2>
        <ParagraphContainer>
          <AboutParagraph2>
            Our platform revolutionizes the seafood
            industry by providing an end-to-end traceability
            solution. With Seabit, you can trace your seafood
            from the moment it is caught to the moment it
            reaches your plate. Utilizing our technology 
            ensures transparency and security, giving you 
            peace of mind that your seafood is safe and
            sustainably sourced.
          </AboutParagraph2>
          <AboutParagraph3>
            Seabit's cutting-edge system provides a 
            solution to one of the biggest challenges facing 
            the seafood industry: the lack of transparency in 
            the supply chain. With Seabit, you can verify the
            origin and authenticity of your seafood, ensuring 
            that it is sourced from ethical practices.
          </AboutParagraph3>
          <AboutParagraph4>
            Fishermen and seafood producers can benefit
            from better market opportunities as their
            practices are verified and rewarded. Retailers and
            restaurants can offer their customers the 
            assurance that their seafood is sustainably
            sourced, which builds trust and loyalty.
          </AboutParagraph4>
        </ParagraphContainer>
        <ImgStyle src={require('../../images/Cuttingboard_Tuna.jpg')}/>
      </AboutContainer2>
      <AboutContainer4>
        <AboutParagraph5>
          Together, let us embark on a journey towards a seafood industry that not only satisfies 
          consumer demands but also protects our oceans, fosters sustainability, and builds a 
          legacy of trust. Seabit presents a compelling opportunity to drive positive change and 
          reshape the industry, making it more accountable, transparent, and prosperous for 
          generations to come.
        </AboutParagraph5>
      </AboutContainer4>
      <AboutTeamHeading>The Team</AboutTeamHeading>
      <AboutContainer3>
        <AboutSub1>
          <LeftContainer>
            <NameHeading>Noa Tomaszewski</NameHeading>
            <NoaImage src={require('../../images/Noa_Smile_Crop.jpg')}/>
            <NameHeading>Co-founder</NameHeading>
            <SocialLocation>
              <Social1Link1 to= "https://www.linkedin.com/in/noa-tomaszewski-3463a3162/" target="_blank" rel="noopener noreferrer">
                <SocialLogo1 src={require('../../images/Linkedin_Logo_Edit.png')}/>
              </Social1Link1>
              <Social1Link2 to= "https://twitter.com/805crypto" target="_blank" rel="noopener noreferrer">
                <SocialLogo2 src={require('../../images/twitter_Logo_Edit.png')}/>
              </Social1Link2>
            </SocialLocation>
          </LeftContainer>
          <RightContainer>
            <AboutNoa>
              Meet Noa, a dynamic and driven 25-year-old co-founder of a cutting-edge seafood tracking 
              system. Noa's exceptional experience and unwavering passion for technology sets him apart 
              in the industry. His relentless drive to create positive change led him to establish the 
              seafood tracking system, addressing the critical need for transparency and accountability 
              in the seafood supply chain. His commitment to excellence, combined with his 
              sharp business acumen, ensures that he is always one step ahead in anticipating 
              industry trends. With Noa's expertise and his ability to navigate the complexities of 
              entrepreneurship, he is poised to transform the way seafood is tracked, ensuring a more 
              transparent and sustainable future for our oceans.
            </AboutNoa>
          </RightContainer>
        </AboutSub1>
      </AboutContainer3>
      <AboutContainer3>
        <AboutSub2>
          <LeftContainer>
            <NameHeading>Kai Tomaszewski</NameHeading>
            <NoaImage src={require('../../images/Kai_About2.jpg')}/>
            <NameHeading>Co-founder</NameHeading>
            <SocialLocation>
              <Social2Link1 to= "https://www.linkedin.com/in/jackson-tomaszewski-57a88414b/" target="_blank" rel="noopener noreferrer">
                <SocialLogo1 src={require('../../images/Linkedin_Logo_Edit.png')}/>
              </Social2Link1>
              <Social2Link2>
                <SocialLogo2 src={require('../../images/twitter_Logo_Edit.png')}/>
              </Social2Link2>
            </SocialLocation>
          </LeftContainer>
          <RightContainer>
            <AboutKai>
              Meet Kai, the accomplished co-founder of an advanced seafood tracking system. At the age of 26, Kai 
              brings a wealth of expertise as an engineer specializing in complex systems. His passion for problem-solving 
              and dedication to creating a positive impact have been the driving force behind his journey as an 
              entrepreneur. Having witnessed the challenges faced by the seafood industry, Kai's commitment to revolutionize 
              the way we track and monitor seafood sourcing sets him apart. By leveraging his expertise, he will ensure that 
              the seafood tracking system operates flawlessly, bringing much-needed transparency and trust to the industry. 
              With Kai at the forefront, we can be confident in a future where seafood is ethically sourced, and our oceans 
              are protected for generations to come.
            </AboutKai>
          </RightContainer>
        </AboutSub2>
      </AboutContainer3>
    </AboutPage>
  );
};

export default About;
