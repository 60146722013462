import React from 'react';
import {
  ContactForm,
  NameField,
  FirstLabel,
  Labels,
  LabelSpan,
  EmailField,
  CompanyField,
  PhoneField,
  MessageField,
  StyledFirst,
  StyledLast,
  StyledEmail,
  StyledCompany,
  StyledMessage,
  SendBtn,
  SendInfo,
  NameFormat,
  DropDown,
  Phone1,
  Phone2,
  ContactWidth,
  ContactHeading,
  SpaceDiv
} from './contactstyle'
import PhoneFormat from './phoneformat';
import Countrycodedropdown from './DropDown/countrycodedropdown';

const Contact = () => {
  return (
    <ContactForm>
      <ContactWidth>
        <ContactHeading>Contact Us</ContactHeading>
        <NameField>
          <NameFormat>
            <FirstLabel>First Name:</FirstLabel>
            <StyledFirst required type='text'></StyledFirst>
          </NameFormat> 
          <NameFormat>
            <FirstLabel>Last Name:</FirstLabel>
            <StyledLast required type='text'></StyledLast>
          </NameFormat>
        </NameField>
        <EmailField>
          <Labels>Email:</Labels>
          <StyledEmail required type='email'></StyledEmail>
        </EmailField>
        <CompanyField>
          <Labels>Company:</Labels>
          <StyledCompany type='text'></StyledCompany>
        </CompanyField>
        <Labels>Phone Number <LabelSpan>(Optional):</LabelSpan></Labels>
        <PhoneField>
          <Phone1>
            <Countrycodedropdown />
          </Phone1>
          <Phone2>
            <PhoneFormat />
          </Phone2>
        </PhoneField>
        <MessageField>
          <Labels>Message:</Labels>
          <StyledMessage required type='text'></StyledMessage>
        </MessageField>
        <SendBtn>
          <SendInfo>Send Message</SendInfo>
        </SendBtn>
        <SpaceDiv></SpaceDiv>
      </ContactWidth>
    </ContactForm>
  );
};

export default Contact;

