import React, { useState } from 'react';
import { countryCodes } from './countrycodedata';
import { DropDown } from '../contactstyle';
import styled from 'styled-components';

export default function Countrycodedropdown() {

  const [selectedValue, setSelectedValue] = useState('');

  return(
    <div>
      <DropDown value={selectedValue} 
        onChange={(e) => setSelectedValue(e.target.value) }>
        {countryCodes.map((codes) => (
          <StyledOptionDefault key={codes.code} value={codes.code}>
            {codes.label}
          </StyledOptionDefault>
      ))}
      </DropDown>
    </div>

  );


}

export const StyledOptionDefault = styled.option`

`;


