import React from 'react';
import { Link } from 'react-router-dom';
import {
  TermsContainer,
  TermsHeading,
  Subheadings,
  IntroParagraph,
  Subheadings1,
  NumSubheading,
  ItalicSubheading,
  NumParagraph,
  CustSpan,
  Subheadings2,
  HyperlinkSpan,
  BoldLetterSpan
} from './copyrightinfringementstyle'

const CopyrightInfringement = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <TermsContainer>
      <TermsHeading>Copyright Infringement Policy</TermsHeading>

      <IntroParagraph>Last Updated: 10/15/2023</IntroParagraph>
      <IntroParagraph>Seabit LLC</IntroParagraph>
      <IntroParagraph>contact@seabit.xyz</IntroParagraph>

      <Subheadings>1. Introduction</Subheadings>
      <IntroParagraph>Seabit LLC ("we," "us," "our") is committed to respecting the intellectual property rights of others. 
        We have a policy to respond to notices of alleged copyright infringement in compliance with the Digital Millennium 
        Copyright Act (DMCA) and other applicable laws. We take copyright infringement seriously and strive to protect the 
        rights of copyright owners as well as ensure the removal of infringing content from our website.
      </IntroParagraph>
      <IntroParagraph>
        This Copyright Infringement Policy outlines the procedures and guidelines for reporting and addressing copyright 
        infringement claims. Please read this policy carefully to understand our commitment to copyright protection.
      </IntroParagraph>

      <Subheadings>2. Reporting Copyright Infringement</Subheadings>
      <IntroParagraph>
        If you believe that your copyrighted work has been used or reproduced on our website in a manner that constitutes 
        copyright infringement, you or your authorized agent may submit a notification following the procedures outlined below. 
        To be effective, your notification must include:
      </IntroParagraph>
      <NumParagraph>
        <BoldLetterSpan>a.</BoldLetterSpan> A Physical or Electronic Signature: A physical or electronic signature of the 
        copyright owner or the person authorized to act on behalf of the copyright owner.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>b.</BoldLetterSpan> Identification of the Infringed Work: Clear identification of the copyrighted work 
        that you claim has been infringed, including a specific description of the content or material in question.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>c.</BoldLetterSpan> Identification of the Infringing Material: Clear identification of the content or 
        material on our website that you claim is infringing, including information that will enable us to locate the material 
        (e.g., a URL).
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>d.</BoldLetterSpan> Contact Information: Your contact information, including your name, mailing address,
         telephone number, and email address.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>e.</BoldLetterSpan> Statement of Good Faith Belief: A statement that you have a good faith belief that the 
        use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>f.</BoldLetterSpan> Statement Under Penalty of Perjury: A statement that the information provided in your 
        notice is accurate, and that you are the copyright owner or authorized to act on behalf of the copyright owner.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>g.</BoldLetterSpan> Electronic Mail Address for Notifications: A valid email address where we can reach 
        you for communication.
      </NumParagraph>
      <IntroParagraph>
        Please send your notification to the designated Copyright Agent for [Your Company Name], whose contact information is 
        provided in the "Contact Information" section of this policy. Note that under Section 512(f) of the DMCA, you may be 
        liable for damages if you knowingly misrepresent that material or activity is infringing. If you are unsure whether the 
        material infringes on your copyright, it is advisable to seek legal counsel before submitting a notice.
      </IntroParagraph>

      <Subheadings>3. Counter Notification</Subheadings>
      <IntroParagraph>
        If your content has been removed from our website due to a copyright infringement claim, you have the right to submit a 
        counter notification. Your counter notification must include:
      </IntroParagraph>
      <NumParagraph>
        <BoldLetterSpan>a.</BoldLetterSpan> Your Physical or Electronic Signature: A physical or electronic signature.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>b.</BoldLetterSpan> Identification of the Removed Material: Clear identification of the material that 
        has been removed and its location before it was removed.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>c.</BoldLetterSpan> A Statement Under Penalty of Perjury: A statement that you have a good faith belief 
        that the material was removed as a result of mistake or misidentification.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>d.</BoldLetterSpan> Your Contact Information: Your contact information, including your name, mailing 
        address, telephone number, and email address.
      </NumParagraph>
      <NumParagraph>
        <BoldLetterSpan>e.</BoldLetterSpan> Consent to Local Federal Court Jurisdiction: A statement consenting to the 
        jurisdiction of the federal district court for the judicial district in which your address is located or, if your 
        address is outside of the United States, consenting to the jurisdiction of the federal courts located within the 
        district of your address.
      </NumParagraph>
      <IntroParagraph>
        Please send your counter notification to the designated Copyright Agent for [Your Company Name], whose contact information 
        is provided in the "Contact Information" section of this policy.
      </IntroParagraph>

      <Subheadings>4. Repeat Infringers</Subheadings>
      <IntroParagraph>It is our policy to terminate, in appropriate circumstances, the accounts of users who are determined to be repeat 
        infringers of copyrights. If you believe that an account holder or user of our website is a repeat infringer, please follow the 
        procedures outlined in this policy to report copyright infringement.
      </IntroParagraph>

      <Subheadings>5. Contact Information</Subheadings>
      <IntroParagraph>
        If you have any questions about this Copyright Infringement Policy or need to report copyright infringement, please contact our 
        designated Copyright Agent:
      </IntroParagraph>
      <IntroParagraph>
        Noa Tomaszewski
      </IntroParagraph>
      <IntroParagraph>
        Seabit LLC
      </IntroParagraph>
      <IntroParagraph>
        1953 Cassia rd, Apt 201
      </IntroParagraph>
      <IntroParagraph>
        Carlsbad, CA, 92011
      </IntroParagraph>
      <IntroParagraph>
        noat19@gmail.com
      </IntroParagraph>
      <IntroParagraph>
        +1-805-903-2721
      </IntroParagraph>

    
    </TermsContainer>
  );
};

export default CopyrightInfringement;
