import styled from 'styled-components';

export const ContactForm = styled.form`
  margin: 4% auto;
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #c9c9c9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 700px;
  width: 500px;
  background-color: white;

  @media screen and (max-width: 1024px) {
    width:55%;
    height:auto;
    margin: 6% auto;
  }

  @media screen and (max-width: 768px) {
    width:60%;
    height:auto;
    margin: 8% auto;
  }

  @media screen and (max-width: 512px) {
    width:70%;
    height: auto;
    margin: 10% auto;
  }
`;

export const ContactWidth = styled.div`


@media screen and (max-width: 1024px) {
  width:70%;
  height:auto;
}

@media screen and (max-width: 768px) {
  width:70%;
  height:auto;
}

@media screen and (max-width: 512px) {
  width: 70%;
  height: auto;
}
`;

export const ContactHeading = styled.h1`

@media screen and (max-width: 1024px) {
  margin-top: 10%;
}

@media screen and (max-width: 768px) {
  font-size: 28px;
  margin-top: 10%;
}

@media screen and (max-width: 512px) {
  font-size: 26px;
  margin-top: 15%;
}
`;

export const NameField = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;


  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
  }
`;

export const NameFormat= styled.div`
  display: inline-block;
  
  

  @media screen and (max-width: 1024px) {
    width:50%;
  }

  @media screen and (max-width: 768px) {
    width:50%;
  }

  @media screen and (max-width: 512px) {
   width:50%;
  }
`;

export const FirstLabel = styled.label`
  display:block;
  

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const Labels = styled.label`
  margin: 2px;
  

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const LabelSpan = styled.span`
  font-size: 12px;
  font-style: italic;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;


export const EmailField = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {
    width:auto;
  }
`;

export const CompanyField = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {

  }
`;

export const PhoneField = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;

  

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {
    width:auto;
  }
`;

export const MessageField = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {

  }
`;

export const StyledFirst = styled.input`
  padding: 8px;
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 6px;
    width:93%;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
    width:91%;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    padding: 4px;
    width:93%;
  }
`;

export const StyledLast = styled.input`
  padding: 8px;
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 6px;
    width:93%;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
    width:90%;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    padding: 4px;
    width:92%;
  }
`;

export const StyledEmail = styled.input`
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;
  padding: 8px;
  
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 6px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    padding: 4px;
  }
`;

export const StyledCompany = styled.input`
  padding: 8px;
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 6px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    padding: 4px;
  }
`;

export const StyledMessage = styled.textarea`
  min-height: 80px;
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    min-height: 70px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    min-height: 60px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    min-height: 60px;
  }
`;

export const SendBtn = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  @media screen and (max-width: 1024px) {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 512px) {
    padding-top: 15px;
    padding-bottom: 20px;
  }
`;

export const SendInfo = styled.div`
  margin-top: 5px;
  background: #4ba9ef;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  border: none;
  text-decoration: none;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {

  }
`;

export const ContactInfo = styled.label`
  margin-top: 30px;
  display:block;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 512px) {

  }
`;

export const DropDown = styled.select`
  padding: 7px;
  border-radius: 5px 0px 0px 5px;
  border: none; 
  border:solid 1px #ccc;
  width: 100px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const Phone1 = styled.div`
  display: inline-block;
  display: flex;



  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {
    
  }

  @media screen and (max-width: 512px) {

  }
`;
export const Phone2 = styled.div`
  display: inline-block;
  display: flex;



  @media screen and (max-width: 1024px) {
    width:100%;
  }

  @media screen and (max-width: 768px) {
    width:100%;
  }

  @media screen and (max-width: 512px) {
    width:100%;
  }
`;

export const SpaceDiv = styled.div`

  @media screen and (max-width: 1024px) {
    margin-top:10%;
    height:10px;
  }

  @media screen and (max-width: 768px) {
    margin-top:10%;
    height:10px;
  }

  @media screen and (max-width: 512px) {
    margin-top:10%;
    height:10px;
  }
`;
