import React from 'react';
import {
  LoginForm,
  UserField,
  SubmitBtn,
  SubmitBtnAct,
  LogInTitle,
  ForgotField,
  ForgotLink,
  SignInProblem,
  ProblemLink,
  Span,
  RedirectLink,
  InputField,
  LabelStyle,
  ErrorMessageDiv,
  MessageFormatDiv
} from  './loginstyle'
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const LoginField = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleClick = async () => {
    
    console.log('Sending sign-in request:', { username, password });
    //Sending requests to express server
    console.log('Button clicked!');

    try {
      const responce = await fetch('http://localhost:5000/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({ username, password }),
        });

      const data = await responce.json()
      console.log(data);

      if (responce.ok) {
        console.log('Successful Sign In')
        toast.success('Successful Sign In', {
          autoClose:true
        });
        navigate('/insights');

      } else{
        toast.error(data.error,{
          closeButton:true,
          autoClose:false,
        });
        setErrorMessage(data.error);
        console.log('Wrong')
      }
    
    } catch (error) {
      console.error('Sign in error', error);
    }
  };

    return (
      <div>
        <LoginForm onSubmit={(e) => e.preventDefault()}>
          <LogInTitle>Sign In</LogInTitle>
          <UserField activeStyle>
            <LabelStyle>Username:</LabelStyle>
            <InputField type='text' value={username} onChange={(e) => setUsername(e.target.value)} required/>
          </UserField>
          <UserField activeStyle>
            <LabelStyle>Password:</LabelStyle>
            <InputField type='password'  value={password} onChange={(e) => setPassword(e.target.value)} required/>
          </UserField>
          <SubmitBtn activeStyle>
            <SubmitBtnAct onClick={handleClick}>Submit</SubmitBtnAct>
          </SubmitBtn>
          <ErrorMessageDiv>
            <MessageFormatDiv>
              <ToastContainer />
            </MessageFormatDiv>
            <ForgotField>
              <ForgotLink>Forgot Password?</ForgotLink>
            </ForgotField>
            <SignInProblem>
              <ProblemLink>
                Problem Signing In?  
                  <RedirectLink to="/contact">
                    <Span> Contact Us
                    </Span>
                  </RedirectLink>
              </ProblemLink>
            </SignInProblem>
          </ErrorMessageDiv>
        </LoginForm>
      </div>
    );
};

export default LoginField;



