import React from 'react';
import { Link } from 'react-router-dom';
import {
  TermsContainer,
  TermsHeading,
  Subheadings,
  IntroParagraph,
  Subheadings1,
  NumSubheading,
  ItalicSubheading,
  NumParagraph,
  CustSpan,
  Subheadings2,
  HyperlinkSpan,
  BoldLetterSpan,
  CustomBulletPoint1,
  CustomBulletPoint2,
  HyperLinkStyle
} from './cookiespolicystyle'

const CookiesPolicy = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <TermsContainer>
      <TermsHeading>Cookies Policy</TermsHeading>

      <Subheadings2>Last Updated: <CustSpan>10/14/2023</CustSpan> </Subheadings2>

      <IntroParagraph>
        Seabit LLC
      </IntroParagraph>
      <IntroParagraph>
        contact@seabit.xyz
      </IntroParagraph>

      <Subheadings>1. Introduction</Subheadings>
      <IntroParagraph>
        At Seabit LLC, we use cookies and similar technologies to enhance your experience on our website, 
        improve our services, and provide you with tailored content. This Cookies Policy explains what cookies 
        are, how we use them, and your choices regarding cookies. By using our website, you consent to the use 
        of cookies in accordance with this policy.
      </IntroParagraph>

      <Subheadings>2. What Are Cookies?</Subheadings>
      <IntroParagraph>
        Cookies are small text files that are stored on your device (computer, smartphone, or tablet) when you 
        visit a website. They serve various functions, such as remembering your preferences, tracking your activities 
        on the site, and providing a personalized browsing experience.
      </IntroParagraph>

      <Subheadings>3. How We Use Cookies</Subheadings>
      <IntroParagraph>
        We use cookies for the following purposes:
      </IntroParagraph>
      <CustomBulletPoint1>
        <CustomBulletPoint2>
          Essential Cookies: These cookies are necessary for the basic functionality of our website, such as enabling you 
          to log in, navigate the site, and use its features.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          Performance Cookies: These cookies help us understand how visitors interact with our website. They collect information 
          on which pages are viewed most, how users move through the site, and if they encounter error messages.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          Functional Cookies: These cookies allow us to remember your choices and preferences, such as your username or language 
          selection. They enhance your experience by personalizing the site's content.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          Advertising Cookies: These cookies are used to deliver advertisements that are relevant to you. They may track your online 
          behavior and help us show ads that match your interests.
        </CustomBulletPoint2>
      </CustomBulletPoint1>

      <Subheadings>4. Types of Cookies We Use</Subheadings>
      <IntroParagraph>
        The specific types of cookies we use include:
      </IntroParagraph>
      <CustomBulletPoint1>
        <CustomBulletPoint2>
          Session Cookies: These cookies are temporary and are deleted from your device once you close your web browser.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          Persistent Cookies: These cookies remain on your device for a specified period or until you manually delete them.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          First-Party Cookies: These cookies are set by Seabit LLC and are used for essential website functions.
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          Third-Party Cookies: These cookies are set by third-party services or providers, such as analytics and advertising services.
        </CustomBulletPoint2>
      </CustomBulletPoint1>

      <Subheadings>5. Managing Cookies</Subheadings>
      <IntroParagraph>
        You have the option to manage or delete cookies. You can do this by adjusting the settings in your web browser. Please note 
        that if you choose to disable cookies, some features of our website may not function properly, and your user experience may 
        be affected.
      </IntroParagraph>
      <IntroParagraph>
        To learn how to manage cookies, you can refer to the help section of your web browser. Below are links to popular browsers' 
        guides on managing cookies:
      </IntroParagraph>
      <CustomBulletPoint1>
        <CustomBulletPoint2>
          <HyperLinkStyle to= "https://support.google.com/chrome/answer/95647?hl=en" 
            target="_blank" rel="noopener noreferrer">
            Google Chrome
          </HyperLinkStyle>
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          <HyperLinkStyle to= "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" 
            target="_blank" rel="noopener noreferrer">
            Mozilla Firefox
          </HyperLinkStyle>
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          <HyperLinkStyle to= "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" 
            target="_blank" rel="noopener noreferrer">
            Safari
          </HyperLinkStyle>
        </CustomBulletPoint2>
        <CustomBulletPoint2>
          <HyperLinkStyle to= "https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" 
            target="_blank" rel="noopener noreferrer">
            Microsoft Edge
          </HyperLinkStyle>
        </CustomBulletPoint2>
      </CustomBulletPoint1>

      <Subheadings>6. Third-Party Cookies</Subheadings>
      <IntroParagraph>
        Some content or features on our website may be provided by third parties. These third parties may also use cookies to track 
        your online behavior. We have no control over these cookies, and you should review the privacy and cookie policies of these 
        third-party services to understand how they use cookies.
      </IntroParagraph>

      <Subheadings>7. Changes to this Cookies Policy</Subheadings>
      <IntroParagraph>
        We may update our Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or 
        regulatory reasons. Any changes to this policy will be posted on this page with the "Last Updated" date. We recommend that you 
        review this policy periodically to stay informed about how we use cookies.
      </IntroParagraph>
      <IntroParagraph>
        If you have any questions or concerns about our use of cookies, please contact us using the information provided in this policy.
      </IntroParagraph>

      <Subheadings>Contact:</Subheadings>
      <IntroParagraph>
        Seabit LLC
      </IntroParagraph>
      <IntroParagraph>
        contact@seabit.xyz
      </IntroParagraph>
    </TermsContainer>
  );
};

export default CookiesPolicy;
