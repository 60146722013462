import React from 'react';
import { Link } from 'react-router-dom';
import {
  TermsContainer,
  TermsHeading,
  Subheadings,
  IntroParagraph,
  Subheadings1,
  NumSubheading,
  ItalicSubheading,
  NumParagraph,
  CustSpan,
  Subheadings2,
  HyperlinkSpan
} from './termsconditionstyle'

const TermsConditions = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <TermsContainer>
      <TermsHeading>Privacy Policy</TermsHeading>

      <Subheadings2>Effective Date: <CustSpan>10/14/2023</CustSpan> </Subheadings2>

      <Subheadings>I. Introduction</Subheadings>
      <IntroParagraph>
        Welcome to Seabit LLC! We are pleased to provide you with our website and services for tracing seafood 
        products. Before accessing or using our website, please carefully read and understand the following Terms 
        and Conditions. These terms govern your use of our website, including any data input and the protection of 
        our proprietary data and information. By accessing or using our website, you agree to be bound by these 
        Terms and Conditions. 
      </IntroParagraph>

      <Subheadings1>A. Types of Information Collected</Subheadings1>
      <IntroParagraph>
        By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound 
        by these Terms and Conditions, as well as any additional terms and policies referenced herein. If you do not 
        agree to these terms, please refrain from accessing or using our website.
      </IntroParagraph>

      <Subheadings1>B. Scope and Purpose of the Document</Subheadings1>
      <IntroParagraph>
        These Terms and Conditions serve as a legally binding agreement between you and Seabit LLC. The 
        purpose of this document is to define the rights, obligations, and responsibilities of both parties regarding the 
        use of our website and services. It covers important topics such as user obligations, intellectual property, 
        data collection and privacy, product tracing, limitation of liability, termination, modifications, governing law, 
        and jurisdiction. 
      </IntroParagraph>
      <IntroParagraph>
        It is important to note that these Terms and Conditions apply specifically to your use of our website and 
        services. They do not govern any transactions or relationships with third-party websites or services that may 
        be linked to or accessed through our website. We encourage you to review the terms and policies of those 
        third-party websites or services for information on their practices and terms of use. 
      </IntroParagraph>
      <IntroParagraph>
        Please take the time to read and understand these Terms and Conditions, as they form a legally binding 
        agreement between you and Seabit LLC. If you have any questions or concerns regarding any aspect of 
        these terms, please contact us using the information provided at the end of this document. 
      </IntroParagraph>
      <IntroParagraph>
        Seabit LLC is committed to protecting your privacy and ensuring the security of your data. Please refer to 
        our Privacy Policy for information on how we collect, use, and protect your personal information. 
      </IntroParagraph>
      <IntroParagraph>
        By proceeding to use our website, you affirm that you have reached the legal age of consent in your 
        jurisdiction and have the authority to enter into this agreement. If you are accessing or using our website on 
        behalf of an organization or entity, you represent and warrant that you have the necessary authority to bind that 
        organization or entity to these Terms and Conditions.
      </IntroParagraph>
      <IntroParagraph>
        Thank you for choosing Seabit LLC. We are dedicated to providing you with a reliable and secure 
        platform for tracing seafood products. 
      </IntroParagraph>

      <Subheadings>II. User Obligations and Responsibilities</Subheadings>

      <Subheadings1>A. Eligibility and Registration</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Eligibility:</ItalicSubheading>
      <NumParagraph>
        By accessing or using our website, you affirm that you are of legal age to enter into this agreement or 
        have obtained the necessary parental or guardian consent. You also warrant that you are not prohibited by 
        any applicable laws or regulations from accessing or using our services. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Registration:</ItalicSubheading>
      <NumParagraph>
        Some features of our website may require you to create an account or provide certain information. You 
        agree to provide accurate, current, and complete information during the registration process. You are 
        solely responsible for maintaining the confidentiality of your account credentials and for any activities 
        that occur under your account. Notify us immediately if you become aware of any unauthorized access to your 
        account. 
      </NumParagraph>

      <Subheadings1>B. Account Security </Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Security Measures:</ItalicSubheading>
      <NumParagraph>
        You are responsible for implementing appropriate security measures to protect your account and any personal 
        information associated with it. This includes safeguarding your login credentials, using strong passwords, 
        and keeping your contact information up to date. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Unauthorized Access:</ItalicSubheading>
      <NumParagraph>
        You agree to notify us promptly if you suspect any unauthorized access to your account or any other security 
        breaches. We shall not be liable for any loss or damage arising from unauthorized access to your account, 
        provided that we have taken reasonable measures to protect your information as outlined in our Privacy Policy. 
      </NumParagraph>

      <Subheadings1>C. User Conduct</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Compliance with Laws:</ItalicSubheading>
      <NumParagraph>
        You agree to use our website and services in compliance with all applicable laws, regulations, and industry 
        standards. You are solely responsible for understanding and adhering to the legal requirements related to your
         use of our website and services, including any specific regulations applicable to the seafood industry. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Prohibited Activities:</ItalicSubheading>
      <NumParagraph>
        You shall not engage in any of the following activities when accessing or using our website: 
      </NumParagraph>
      <NumParagraph>
        a. Violating any intellectual property rights or proprietary rights of Seabit LLC or any third party. 
      </NumParagraph>
      <NumParagraph>
        b. Uploading, transmitting, or distributing any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable. 
      </NumParagraph>
      <NumParagraph>
        c. Impersonating any person or entity or falsely stating or misrepresenting your affiliation with a person or entity.
      </NumParagraph>
      <NumParagraph>
        d. Engaging in any activity that could disrupt the functionality of our website or interfere with the experience of other users. 
      </NumParagraph>
      <NumParagraph>
        e. Attempting to gain unauthorized access to our systems, accounts, or networks. 
      </NumParagraph>
      <NumParagraph>
        f. Collecting or harvesting any information, including personal data, from our website without proper authorization. 
      </NumParagraph>

      <Subheadings1>D. Compliance with Laws and Regulations</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Regulatory Compliance:</ItalicSubheading>
      <NumParagraph>
        You agree to comply with all applicable laws, regulations, and guidelines relating to your use of our website and 
        services. This includes but is not limited to laws regarding data protection, privacy, intellectual property, and 
        export controls. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Indemnification: </ItalicSubheading>
      <NumParagraph>
        You agree to indemnify, defend, and hold Seabit LLC harmless from and against any claims, damages, liabilities, 
        costs, and expenses arising out of or related to your breach of any obligations or responsibilities outlined in 
        these Terms and Conditions or any applicable laws or regulations.
      </NumParagraph>
      <IntroParagraph>
        It is essential to understand and comply with your obligations and responsibilities as a user of our website and 
        services. Failure to adhere to these obligations may result in the suspension or termination of your account and 
        may also expose you to legal liabilities. If you have any questions regarding your obligations or need further 
        clarification, please contact us using the information provided in this document. 
      </IntroParagraph>


      <Subheadings>III. Intellectual Property </Subheadings>

      <Subheadings1>A. Ownership of Content </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Seabit LLC Intellectual Property:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC has filed a trademark application for our company name. All rights to the name “Seabit” are reserved, and 
        any use or reproduction of our trademark without written permission is strictly prohibited. The images and other content 
        displayed on our website, which are not explicitly identified as trademarked or proprietary, are sourced from free and 
        publicly available repositories and are used under licenses that permit their use. You may not use, modify, distribute, 
        or reproduce the Seabit LLC trademark without our written permission, but you may use and share the other content in 
        accordance with the respective licenses and terms associated with those materials.
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Limited License:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC grants you a limited, non-exclusive, non-transferable license to access and use our website and its 
        content solely for the purpose of utilizing the services offered. This license does not grant you any rights to 
        reproduce, modify, distribute, or publicly display our intellectual property without our prior written consent. 
      </NumParagraph>

      <Subheadings1>B. Restrictions on Use</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>User-Generated Content:</ItalicSubheading>
      <NumParagraph>
        If you contribute any content, comments, suggestions, or ideas to our website, you grant Seabit LLC a 
        non-exclusive, worldwide, royalty-free, and perpetual license to use, reproduce, modify, distribute, and display 
        such content for the purposes of providing and promoting our services. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Prohibited Actions:</ItalicSubheading>
      <NumParagraph>
        You agree not to engage in any actions that would infringe upon or violate the intellectual property rights of 
        Seabit LLC or any third party. This includes but is not limited to: 
      </NumParagraph>
      <NumParagraph>
        a. Copying, reproducing, or modifying any content or materials on our website without authorization. 
      </NumParagraph>
      <NumParagraph>
        b. Reverse-engineering, decompiling, or attempting to extract the source code of our website or any related software. 
      </NumParagraph>
      <NumParagraph>
        c. Using our intellectual property for any unauthorized commercial purposes or in a manner that could harm the 
        reputation of Seabit LLC.
      </NumParagraph>

      <Subheadings1>C. Trademarks </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Trademark Ownership:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC and any associated logos, slogans, or product names are trademarks or filed  trademarks of Seabit 
        LLC. Other trademarks, service marks, logos, or product names appearing on our website are the property of their
         respective owners. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Unauthorized Use:</ItalicSubheading>
      <NumParagraph>
        You shall not use any trademarks or trade names of Seabit LLC without our prior written consent. Any unauthorized 
        use may constitute a violation of trademark laws and may result in legal action. 
      </NumParagraph>

      <Subheadings1>D. Copyright Infringement </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>DMCA Compliance:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC respects the intellectual property rights of others and expects users of our website to do the same. 
        If you believe that your copyright-protected work has been infringed upon, please follow 
        our <HyperlinkSpan onClick={scrollToTop}><Link to="/copyrightinfringement">Copyright Infringement 
        Policy</Link></HyperlinkSpan>, which is available on our website and provides instructions for reporting such infringements. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Repeat Infringers:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC reserves the right to terminate the accounts of users who repeatedly infringe the copyrights of others. 
      </NumParagraph>

      <IntroParagraph>
        Protecting intellectual property is of utmost importance to Seabit LLC. We take any infringement of our intellectual 
        property rights seriously and will pursue legal action when necessary. Similarly, we expect our users to respect the 
        intellectual property rights of others and to comply with all applicable copyright laws. 
      </IntroParagraph>
      <IntroParagraph>
        If you have any questions regarding our intellectual property rights or need further clarification, please contact 
        us using the information provided in this document. 
      </IntroParagraph>

      <Subheadings>IV. Data Collection and Privacy</Subheadings>

      <Subheadings1>A. Data Collection</Subheadings1>
      
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Data Accuracy:</ItalicSubheading>
      <NumParagraph>
        You are responsible for ensuring the accuracy and completeness of the information you provide to us. Please 
        promptly update any changes to your personal information to ensure its accuracy.
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Information We Collect:</ItalicSubheading>
      <NumParagraph>
        When you access or use our website, we may collect certain information from you, including personal information 
        and non-personal information. This information may include but is not limited to: 
      </NumParagraph>

      <NumParagraph>
        a. Personal Information: Name, email address, contact information, and any other information you voluntarily 
        provide to us. 
      </NumParagraph>
      <NumParagraph>
        b. Log Data: Information about your interactions with our website, such as IP address, browser type, pages 
        visited, and timestamps. 
      </NumParagraph>
      <NumParagraph>
        c. Cookies and Similar Technologies: We may use cookies and similar technologies to collect information about 
        your browsing preferences and improve your user experience. 
      </NumParagraph>

      <Subheadings1>B. Data Use and Protection</Subheadings1>
      
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Data Security:</ItalicSubheading>
      <NumParagraph>
        We take reasonable measures to protect your information from unauthorized access, loss, misuse, or alteration. 
        However, please be aware that no data transmission over the internet or storage system is entirely secure. We 
        cannot guarantee the absolute security of your information. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Use of Information:</ItalicSubheading>
      <NumParagraph>
        We may use the collected information for the following purposes: 
      </NumParagraph>
      <NumParagraph>
        a. Providing and improving our services to you. 
      </NumParagraph>
      <NumParagraph>
        b. Responding to your inquiries and providing customer support. 
      </NumParagraph>
      <NumParagraph>
        c. Sending you important notices and updates related to our services. 
      </NumParagraph>
      <NumParagraph>
        d. Analyzing website usage patterns and trends to enhance user experience and optimize our website's functionality. 
      </NumParagraph>


      <Subheadings1>C. Data Sharing and Third Parties</Subheadings1>
      
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Third-Party Links:</ItalicSubheading>
      <NumParagraph>
        Our website may contain links to third-party websites or services. Please note that we are not responsible for the 
        privacy practices or content of those third parties. We encourage you to review their privacy policies before providing 
        any personal information to them. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Sharing of Information:</ItalicSubheading>
      <NumParagraph>
        We may share your information in the following circumstances: 
      </NumParagraph>
      <NumParagraph>
        a. Service Providers: We may engage trusted third-party service providers to assist us in providing and improving our 
        services. These service providers are contractually obligated to maintain the confidentiality and security of your information. 
      </NumParagraph>
      <NumParagraph>
        b. Legal Compliance: We may disclose your information if required to do so by law or in response to valid legal requests or proceedings. 
      </NumParagraph>
      <NumParagraph>
        c. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be 
        transferred as part of the transaction. 
      </NumParagraph>

      <Subheadings1>D. Data Retention</Subheadings1>
      
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Retention Period:</ItalicSubheading>
      <NumParagraph>
        We will retain your information for as long as necessary to fulfill the purposes outlined in this document, unless a longer 
        retention period is required or permitted by law. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>User Rights:</ItalicSubheading>
      <NumParagraph>
        You may have certain rights regarding the access, correction, and deletion of your personal information. For more 
        information on exercising your rights, please refer to our Privacy Policy. 
      </NumParagraph>

      <Subheadings1>E. Consent and Agreement</Subheadings1>
      
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Consent:</ItalicSubheading>
      <NumParagraph>
        By using our website, you consent to the collection, use, and sharing of your information as described in this document and our 
        Privacy Policy. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Children's Privacy:</ItalicSubheading>
      <NumParagraph>
        Our website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from 
        individuals under this age. If you are a parent or guardian and believe that your child has provided us with personal information, 
        please contact us immediately. 
      </NumParagraph>
      <IntroParagraph>
        Protecting your privacy and data is a top priority for Seabit LLC. We are committed to maintaining the confidentiality and security 
        of your information. For more details on how we collect, use, and protect your information, please refer to 
        our <HyperlinkSpan onClick={scrollToTop} ><Link to="/privacy">Privacy Policy</Link></HyperlinkSpan> , which 
        is available on our website. If you have any questions or concerns regarding your data privacy or any other aspect of our practices, 
        please contact us using the information provided in this document. 
      </IntroParagraph>

      <Subheadings>V. Product Tracing and Information Accuracy</Subheadings>

      <Subheadings1>A. Product Tracing </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Purpose: </ItalicSubheading>
      <NumParagraph>
        Our website provides a product tracing service in the seafood industry, allowing users to track and obtain information about the 
        origin, quality, and handling of seafood products. This service aims to promote transparency, sustainability, and consumer confidence
         in the seafood supply chain. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Data Sources:</ItalicSubheading>
      <NumParagraph>
        We rely on various sources of information, including but not limited to suppliers, distributors, regulatory agencies, and third-party 
        databases, to gather accurate and up-to-date data about the traced products. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Data Representation:</ItalicSubheading>
      <NumParagraph>
        While we strive to provide reliable and accurate information, it is important to note that the tracing results are based on the data 
        available to us at the time of retrieval. We make reasonable efforts to ensure the accuracy of the information, but we cannot guarantee 
        its absolute correctness or completeness. 
      </NumParagraph>

      <Subheadings1>B. Information Accuracy</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>User Responsibilities:</ItalicSubheading>
      <NumParagraph>
        As a user of our website, you are responsible for reviewing and verifying the accuracy of the traced product information provided. 
        It is essential to cross-reference the obtained data with other sources or consult with relevant experts to validate the information's 
        accuracy and applicability to your specific needs. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Limitations and Disclaimers:</ItalicSubheading>
      <NumParagraph>
        We provide the traced product information as a service and make no representations or warranties of any kind, whether expressed or 
        implied, regarding the accuracy, reliability, suitability, or availability of the information. We disclaim any liability for any 
        errors, omissions, or inaccuracies in the traced product information. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Independent Verification: </ItalicSubheading>
      <NumParagraph>
        We strongly encourage users to exercise their own judgment and independently verify the traced product information before making 
        any decisions or taking any actions based on the provided data. This includes but is not limited to assessing product quality, 
        safety, compliance with regulations, and adherence to ethical standards. 
      </NumParagraph>

      <Subheadings1>C. Proprietary Data and Unauthorized Use </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Proprietary Information:</ItalicSubheading>
      <NumParagraph>
        The traced product information, including any associated data, algorithms, methodologies, and processes used on our website, 
        is proprietary and confidential to Seabit LLC. This information constitutes valuable intellectual property and trade secrets 
        protected by applicable laws. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Unauthorized Use:</ItalicSubheading>
      <NumParagraph>
        You are strictly prohibited from reproducing, modifying, reverse-engineering, decompiling, or creating derivative works based 
        on our proprietary information without our explicit written consent. Any unauthorized use, access, or disclosure of our proprietary 
        data may result in legal action and substantial damages. 
      </NumParagraph>

      <Subheadings1>D. Limitation of Liability</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Assumption of Risk:</ItalicSubheading>
      <NumParagraph>
        By using our product tracing service, you acknowledge and accept the inherent risks associated with relying on traced product 
        information. We do not guarantee the accuracy, completeness, or reliability of the traced data, and you agree to use the information 
        at your own risk. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Limitation of Liability:</ItalicSubheading>
      <NumParagraph>
        In no event shall Seabit LLC, its officers, directors, employees, or affiliates be liable for any direct, indirect, incidental, 
        consequential, or special damages arising out of or in connection with the use of our product tracing service or the reliance on 
        traced product information, even if advised of the possibility of such damages. 
      </NumParagraph>

      <IntroParagraph>
        It is important to recognize that traced product information serves as a tool to enhance transparency and understanding in the 
        seafood industry. However, users must exercise diligence, critical judgment, and independent verification when interpreting and 
        relying on the provided information. If you have any questions or concerns about the traced product information or its accuracy, 
        please contact us using the information provided in this document. 
      </IntroParagraph>

      <Subheadings>VI. Limitation and Liability </Subheadings>

      <Subheadings1>A. Assumption of Risk</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Use of the Website:</ItalicSubheading>
      <NumParagraph>
        By accessing and using our website for product tracing in the seafood industry, you acknowledge and accept the inherent risks 
        involved. You understand that the accuracy, completeness, and timeliness of the traced product information are not guaranteed. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Independent Verification:</ItalicSubheading>
      <NumParagraph>
        You agree to independently verify the traced product information and exercise your own judgment when relying on it. We recommend 
        consulting with experts or conducting additional research to validate the information's accuracy and suitability for your specific 
        purposes. 
      </NumParagraph>

      <Subheadings1>B. Limitation of Liability </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>No Warranty:</ItalicSubheading>
      <NumParagraph>
        We provide our website and services on an "as is" and "as available" basis. We make no representations or warranties, express or 
        implied, regarding the accuracy, reliability, suitability, or availability of the traced product information or the website itself. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Limitation of Liability:</ItalicSubheading>
      <NumParagraph>
        Our liability, and the liability of our officers, directors, employees, or affiliates, for any claim arising out of or related to 
        our website or services, shall be limited to the extent permitted by law. In no event shall our total liability exceed the amount 
        you paid, if any, for accessing or using our website or services. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Exclusion of Liability:</ItalicSubheading>
      <NumParagraph>
        To the fullest extent permitted by applicable law, Seabit LLC, its officers, directors, employees, or affiliates shall not be 
        liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with:
      </NumParagraph>
      <NumParagraph>
        a. The use or inability to use our website or services. 
      </NumParagraph>
      <NumParagraph>
        b. Reliance on the traced product information or any other content available on our website. 
      </NumParagraph>
      <NumParagraph>
        c. Unauthorized access to or alteration of your data. 
      </NumParagraph>
      <NumParagraph>
        d. Any conduct or content of third-party websites linked to or from our website. 
      </NumParagraph>
      <NumParagraph>
        e. Any errors or omissions in the traced product information or any other content. 
      </NumParagraph>

      <Subheadings1>C. Indemnification</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Cooperation:</ItalicSubheading>
      <NumParagraph>
        We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to 
        indemnification by you. You agree to cooperate with our defense of such claims. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Your Responsibility:</ItalicSubheading>
      <NumParagraph>
        You agree to indemnify, defend, and hold Seabit LLC, its officers, directors, employees, and affiliates harmless from any 
        claims, damages, losses, liabilities, costs, or expenses (including reasonable attorneys' fees) arising out of or relating to: 
      </NumParagraph>
      <NumParagraph>
        a. Your use of our website or services. 
      </NumParagraph>
      <NumParagraph>
        b. Violation of these Terms and Conditions. 
      </NumParagraph>
      <NumParagraph>
        c. Any third-party claims arising from your actions or omissions. 
      </NumParagraph>

      <Subheadings1>D. Governing Law and Jurisdiction</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Applicable Law:</ItalicSubheading>
      <NumParagraph>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of Delaware, without regard to its 
        conflict of laws principles. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Jurisdiction:</ItalicSubheading>
      <NumParagraph>
        Any legal action or proceeding arising out of or relating to these Terms and Conditions or your use of our website shall be 
        exclusively brought in the courts located in Delaware. You consent to the jurisdiction of such courts and waive any objection 
        to the venue or inconvenient forum. 
      </NumParagraph>
      <IntroParagraph>
        It is important to understand the limitations of our website and services. The traced product information provided should be 
        verified independently, and you should use your own judgment when relying on it. By accessing and using our website, you agree 
        to release Seabit LLC from any liability arising from the use or reliance on the traced product information. If you have any 
        questions or concerns about these limitations, please contact us using the information provided in this document. 
      </IntroParagraph>

      <Subheadings>VII. Termination</Subheadings>

      <Subheadings1>A. Termination by Seabit LLC</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Right to Terminate:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC reserves the right to terminate or suspend your access to and use of our website and services at any time and for 
        any reason without prior notice. Such termination may result in the deletion of your account and the removal of any content 
        associated with it. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Reasons for Termination:</ItalicSubheading>
      <NumParagraph>
        We may terminate your access to our website if we believe, in our sole discretion, that you have violated these Terms and 
        Conditions, engaged in fraudulent or unlawful activities, or acted in a manner that is detrimental to the operation or reputation 
        of Seabit LLC. 
      </NumParagraph>

      <Subheadings1>B. Termination by User </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Voluntary Termination:</ItalicSubheading>
      <NumParagraph>
        You have the right to terminate your use of our website and services at any time by discontinuing your access and ceasing to 
        use our platform. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Account Deletion:</ItalicSubheading>
      <NumParagraph>
        If you choose to terminate your account, please be aware that it may result in the permanent deletion of your account information, 
        including any data, content, or personal information associated with it. We are not responsible for retaining or providing any such 
        information after termination. 
      </NumParagraph>

      <Subheadings1>D. Survival</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Survival of Certain Provisions:</ItalicSubheading>
      <NumParagraph>
        The termination of these Terms and Conditions shall not affect the validity or enforceability of any provision that, by its nature,
         is intended to survive termination, including but not limited to the sections related to Intellectual Property, Limitation and Liability, 
         Indemnification, and Governing Law. 
      </NumParagraph>
      
      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Post-Termination Access:</ItalicSubheading>
      <NumParagraph>
        We reserve the right to retain certain information and data related to your use of our website even after termination for legitimate 
        business purposes, such as for legal compliance, record-keeping, or to protect our rights or the rights of other users. 
      </NumParagraph>
      <IntroParagraph>
        It is important to understand that Seabit LLC has the right to terminate or suspend your access to our website and services for various 
        reasons, including violations of these Terms and Conditions. Additionally, you have the right to voluntarily terminate your use of our 
        platform. Please note that termination may have certain consequences, and certain obligations and provisions will survive termination. 
        If you have any questions or concerns regarding the termination process, please reach out to us using the contact information provided 
        in this document. 
      </IntroParagraph>

      <Subheadings>VIII. Modifications and Amendments</Subheadings>

      <Subheadings1>A. Right to Modify</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Modification of Terms and Conditions:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC reserves the right to modify, update, or amend these Terms and Conditions at any time and in its sole discretion. Any 
        changes will be effective immediately upon posting the revised version on our website. It is your responsibility to review the Terms 
        and Conditions periodically for any updates or changes. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Notice of Modifications: </ItalicSubheading>
      <NumParagraph>
        We may provide notice of material changes to these Terms and Conditions through prominent notification on our website or by other means 
        we deem appropriate. However, it is your responsibility to regularly check for any updates to ensure compliance with the most current 
        version. 
      </NumParagraph>

      <Subheadings1>B. Acceptance of Modifications </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Continued Use:</ItalicSubheading>
      <NumParagraph>
        By continuing to access or use our website after the modified Terms and Conditions have been posted, you agree to be bound by the revised 
        terms. If you do not agree with the modifications, you should discontinue your use of our website and services. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>No Retroactive Application:</ItalicSubheading>
      <NumParagraph>
        Modifications to these Terms and Conditions will not apply retroactively and will only govern the use of our website and services after 
        the effective date of the modifications. 
      </NumParagraph>

      <Subheadings1>C. Amendment Procedure</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Amendment Process:</ItalicSubheading>
      <NumParagraph>
        In the event that we propose amendments to these Terms and Conditions that materially affect your rights or obligations, we will provide 
        you with a separate notice and seek your explicit consent before implementing such changes. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Refusal of Amendments:</ItalicSubheading>
      <NumParagraph>
        You have the right to refuse any proposed amendments to these Terms and Conditions. However, if you refuse to accept the proposed changes, 
        Seabit LLC may have to terminate your access to our website and services. 
      </NumParagraph>

      <Subheadings1>D. Severability</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Severability Clause:</ItalicSubheading>
      <NumParagraph>
        If any provision of these Terms and Conditions is deemed invalid, illegal, or unenforceable by a court of competent jurisdiction, 
        the remaining provisions shall remain in full force and effect to the maximum extent permitted by law.
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Modification of Invalid Provisions:</ItalicSubheading>
      <NumParagraph>
        In the event that any provision of these Terms and Conditions is found to be invalid, illegal, or unenforceable, we will replace such 
        provision with a valid and enforceable provision that most closely reflects the original intention of the parties. 
      </NumParagraph>

      <IntroParagraph>
        It is our prerogative to modify, update, or amend these Terms and Conditions as necessary. We will make reasonable efforts to notify 
        you of any material changes, but it is ultimately your responsibility to stay informed about the current version. By continuing to use 
        our website after modifications have been made, you accept the revised terms. However, if you disagree with the modifications, you have 
        the option to discontinue your use of our platform. We may also seek your explicit consent for amendments that materially affect your 
        rights or obligations. If any provision is deemed invalid, the remaining provisions will continue to be in effect. Please review these 
        Terms and Conditions periodically to ensure compliance. 
      </IntroParagraph>

      <Subheadings>IX. Governing Law and Jurisdiction </Subheadings>

      <Subheadings1>A. Applicable Law</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Choice of Law:</ItalicSubheading>
      <NumParagraph>
        These Terms and Conditions and any disputes arising out of or related to your use of our website and services shall be governed by and 
        construed in accordance with the laws of Delaware, without regard to its conflict of laws principles. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>International Users:</ItalicSubheading>
      <NumParagraph>
        If you access our website from outside Delaware or if you are an international user, you agree to comply with all local laws, rules, 
        and regulations that apply to your use of our website and services. 
      </NumParagraph>
      
      <Subheadings1>B. Jurisdiction</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Exclusive Jurisdiction:</ItalicSubheading>
      <NumParagraph>
        Any legal action, proceeding, or dispute arising under or in connection with these Terms and Conditions shall be subject to the 
        exclusive jurisdiction of the courts located in Delaware, and you hereby consent to the personal jurisdiction of such courts. 
      </NumParagraph>
      
      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Venue:</ItalicSubheading>
      <NumParagraph>
        You agree that any legal action, proceeding, or dispute brought against Seabit LLC shall be brought exclusively in the courts 
        located in Delaware, and you waive any objection to such venue on grounds of inconvenient forum. 
      </NumParagraph>

      <Subheadings1>C. Compliance with Laws</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Legal Compliance:</ItalicSubheading>
      <NumParagraph>
        You agree to comply with all applicable laws, regulations, and requirements in connection with your use of our website and 
        services. This includes, but is not limited to, laws related to data protection, privacy, intellectual property, and consumer 
        rights. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Export Control:</ItalicSubheading>
      <NumParagraph>
        You acknowledge and agree that the export of certain technologies, software, and information may be subject to export control 
        laws and regulations. You agree not to export or re-export any such technologies, software, or information in violation of 
        applicable export control laws. 
      </NumParagraph>
      <IntroParagraph>
        It is important to understand that these Terms and Conditions are governed by the laws of Delaware. By using our website and 
        services, you agree to comply with all applicable laws and regulations, regardless of your location. Any legal disputes or 
        proceedings arising from these Terms and Conditions will be exclusively handled by the courts located in Delaware. You consent 
        to the personal jurisdiction of these courts and waive any objection to the venue. Additionally, you are responsible for 
        understanding and complying with any export control laws and regulations that may apply. 
      </IntroParagraph>

      <Subheadings>X. Miscellaneous </Subheadings>

      <Subheadings1>A. Entire Agreement </Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Entire Agreement Clause:</ItalicSubheading>
      <NumParagraph>
        These Terms and Conditions constitute the entire agreement between you and Seabit LLC concerning the subject matter herein and 
        supersede all prior and contemporaneous negotiations and agreements, whether oral or written. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>No Waiver:</ItalicSubheading>
      <NumParagraph>
        The failure of Seabit LLC to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a 
        waiver of such right or provision. 
      </NumParagraph>

      <Subheadings1>B. Assignment</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>No Assignment by User:</ItalicSubheading>
      <NumParagraph>
        You may not assign, transfer, or sublicense any of your rights or obligations under these Terms and Conditions without the prior 
        written consent of Seabit LLC. Any attempted assignment without consent will be null and void. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Assignment by Seabit LLC:</ItalicSubheading>
      <NumParagraph>
        Seabit LLC may assign, transfer, or delegate any of its rights and obligations under these Terms and Conditions without restriction. 
        This includes, but is not limited to, any assignment in connection with a merger, acquisition, or sale of assets. 
      </NumParagraph>

      <Subheadings1>C. Severability</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Severability Clause:</ItalicSubheading>
      <NumParagraph>
        If any provision of these Terms and Conditions is deemed invalid, illegal, or unenforceable by a court of competent jurisdiction, 
        the remaining provisions shall remain in full force and effect to the maximum extent permitted by law. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Modification of Invalid Provisions:</ItalicSubheading>
      <NumParagraph>
        In the event that any provision of these Terms and Conditions is found to be invalid, illegal, or unenforceable, we will replace 
        such provision with a valid and enforceable provision that most closely reflects the original intention of the parties. 
      </NumParagraph>

      <Subheadings1>D. Headings</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Headings for Convenience:</ItalicSubheading>
      <NumParagraph>
        The headings used in these Terms and Conditions are for convenience purposes only and shall not affect the interpretation or 
        construction of these terms. 
      </NumParagraph>

      <Subheadings1>E. Relationship</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>No Agency or Partnership:</ItalicSubheading>
      <NumParagraph>
        These Terms and Conditions do not create any agency, partnership, joint venture, or employment relationship between you and 
        Seabit LLC. You agree that you are an independent contractor and are solely responsible for compliance with any applicable 
        laws or regulations related to your use of our website and services. 
      </NumParagraph>

      <Subheadings1>F. Language</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Language of Agreement:</ItalicSubheading>
      <NumParagraph>
        These Terms and Conditions are drafted in the English language. In the event of any inconsistency or discrepancy between the 
        English version and any translated version, the English version shall prevail. 
      </NumParagraph>

      <IntroParagraph>
        It is important to understand that these Terms and Conditions represent the entire agreement between you and Seabit LLC and 
        supersede any previous agreements or negotiations. Seabit LLC failure to enforce any right or provision in these terms does 
        not constitute a waiver of that right or provision. You are not allowed to assign your rights or obligations under these Terms 
        and Conditions without prior written consent from Seabit LLC. However, Seabit LLC has the right to assign or transfer its rights 
        and obligations. If any provision of these Terms and Conditions is found to be invalid, the remaining provisions will continue 
        to be in effect. The headings used in these terms are for convenience only. These Terms and Conditions do not create an agency, 
        partnership, joint venture, or employment relationship between you and Seabit LLC. The agreement is written in the English 
        language, and in case of any discrepancies, the English version will prevail. 
      </IntroParagraph>

      <Subheadings>XI. Contact Information </Subheadings>

      <IntroParagraph>If you have any question, concerns, or inquiries regarding these Terms, please contact us using the following information:</IntroParagraph>
      <IntroParagraph>Seabit LLC</IntroParagraph>
      <IntroParagraph>contact@seabit.xyz</IntroParagraph>
    </TermsContainer>
  );
};

export default TermsConditions;
