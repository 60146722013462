import React from 'react';
import {
  FooterContainer1,
  FooterColumn1,
  FooterColumn,
  FooterMain,
  HeadingFooter,
  FooterPrivacy,
  FooterLogo,
  ColumnFooterText,
  FooterTextTest,
  FooterPrivacyLocation,
  MailImage,
  LocationLine,
  EmailLine,
  LocationImage,
  RocketContainer,
  CloudLocation,
  CloudImage,
  LogoLink,
  MobileElementContainer,
  FooterColumnMobile,
  FooterColumn1Mobile,
  FooterPrivacyMobile,
  FooterPrivacyLocationMobile,
  MobileElementSubContainer,
  FooterLogoMobile
} from './footerstyle'

import { useSharedContext } from '../States/SharedContext';

const Footer = () => {

  const { isMenuOpen, toggleMenu} = useSharedContext();

  console.log('FooterState - isMenuOpen:', isMenuOpen);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  const collapseMenu = () => {
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  const twoClicks = () => {
    scrollToTop();
    collapseMenu();
  };
  return (
    <RocketContainer>
      <CloudLocation>
        <CloudImage />
        <CloudImage />
        <CloudImage />
        <CloudImage />
      </CloudLocation>
      <FooterContainer1>
            <FooterMain>
              <FooterColumn1>
                  <LogoLink onClick={scrollToTop} to='/'>
                    <FooterLogo src={require('../../images/Seabit_Plus_Bait_2.png')} alt='logo'/>
                  </LogoLink>
                  <LocationLine>
                    <MailImage src={require('../../images/Location_Icon.png')} alt='mail'/>
                    <ColumnFooterText>Carlsbad, CA</ColumnFooterText>
                  </LocationLine>
                  <EmailLine>
                    <LocationImage src={require('../../images/Mail_Icon.png')} alt='mail'/>
                    <ColumnFooterText>contact@seabit.co</ColumnFooterText>
                  </EmailLine>
                </FooterColumn1>
                <FooterColumn>
                  <HeadingFooter>Site Links</HeadingFooter>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/about" >About</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/contact">Contact</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/map">Map</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/tracking">Tracking</FooterTextTest>
                  </div>
                </FooterColumn>
                <FooterColumn>
                  <HeadingFooter>Resources</HeadingFooter>
                  <div>
                    <FooterTextTest to= "https://www.fao.org/home/en/" target="_blank" rel="noopener noreferrer">FAO</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest to= "https://www.fda.gov/" target="_blank" rel="noopener noreferrer" >FDA</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest to= "https://www.marineregions.org/" target="_blank" rel="noopener noreferrer">Marine Regions</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest to= "https://www.noaa.gov/" target="_blank" rel="noopener noreferrer">NOAA</FooterTextTest>
                  </div>
                </FooterColumn>
                <FooterColumn>
                  <HeadingFooter>More</HeadingFooter>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/blog">Blogs</FooterTextTest>
                  </div>
                    <FooterTextTest onClick={scrollToTop} to="/faq">FAQ</FooterTextTest>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/pressrelease">Press Releases</FooterTextTest>
                  </div>
                  <div>
                    <FooterTextTest onClick={scrollToTop} to="/whitepaper">Whitepaper</FooterTextTest>
                  </div>
                </FooterColumn>
              </FooterMain>
              <FooterPrivacyLocation>
                <FooterPrivacy onClick={scrollToTop} to="/privacy">Privacy Policy</FooterPrivacy>
                <FooterPrivacy>-</FooterPrivacy>
                <FooterPrivacy onClick={scrollToTop} to="/termsconditions">Terms & Conditions</FooterPrivacy>
              </FooterPrivacyLocation>
              <MobileElementContainer isMenuOpen = {isMenuOpen} >
                <MobileElementSubContainer>
                  <FooterColumn1Mobile>
                    <LogoLink onClick={scrollToTop} to='/'>
                      <FooterLogoMobile src={require('../../images/Seabit_Plus_Bait_2.png')} alt='logo'/>
                    </LogoLink>
                    <LocationLine>
                      <MailImage src={require('../../images/Location_Icon.png')} alt='mail'/>
                      <ColumnFooterText>Carlsbad, CA</ColumnFooterText>
                    </LocationLine>
                    <EmailLine>
                      <LocationImage src={require('../../images/Mail_Icon.png')} alt='mail'/>
                      <ColumnFooterText>contact@seabit.co</ColumnFooterText>
                    </EmailLine>
                  </FooterColumn1Mobile>
                  <FooterColumnMobile>
                    <HeadingFooter>Site Links</HeadingFooter>
                    <div>
                      <FooterTextTest onClick={twoClicks} to="/about" >About</FooterTextTest>
                    </div>
                    <div>
                      <FooterTextTest onClick={twoClicks} to="/contact">Contact</FooterTextTest>
                    </div>
                    <div>
                      <FooterTextTest onClick={twoClicks} to="/map">Map</FooterTextTest>
                    </div>
                    <div>
                      <FooterTextTest onClick={twoClicks} to="/tracking">Tracking</FooterTextTest>
                    </div>
                  </FooterColumnMobile>
                </MobileElementSubContainer>
              </MobileElementContainer>
              <FooterPrivacyLocationMobile>
                <FooterPrivacyMobile onClick={scrollToTop} to="/privacy">Privacy Policy</FooterPrivacyMobile>
                <FooterPrivacyMobile>-</FooterPrivacyMobile>
                <FooterPrivacyMobile onClick={scrollToTop} to="/termsconditions">Terms & Conditions</FooterPrivacyMobile>
              </FooterPrivacyLocationMobile>
      </FooterContainer1> 
    </RocketContainer>
    
  );
};

export default Footer;
