export const countryCodes =[
  {
    value:"+1", 
    label: "USA: +1"
  },
  {
    value:"+44", 
    label: "GBR: +44"
  },
  {
    value:"+44", 
    label: "SVN: +44"
  },
  {
    value:"+48", 
    label: "POL: +48"
  },
  {
    value:"+974", 
    label: "QAT: +974"
  },
  {
    value:"+351", 
    label: "PRT: +351"
  },
  {
    value:"+81", 
    label: "JPN: +81"
  },
  {
    value:"+39", 
    label: "ITA: +39"
  },
]