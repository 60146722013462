import React from 'react';

const WhitePaper = () => {
  return (
    <div
      style={{
      }}
    >
      <h1>WhitePaper</h1>
    </div>
  );
};

export default WhitePaper;
