import React from 'react';

const FAQ = () => {
  return (
    <div
      style={{
      }}
    >
      <h1>FAQ</h1>
    </div>
  );
};

export default FAQ;
