import React from 'react';

const Restricted = () => {
  return (
    <div
      style={{
      }}
    >
      <h1>Restricted</h1>
    </div>
  );
};

export default Restricted;
