import React from 'react';
import {
  Background,
  TextLocation,
  ButtonLocation,
  ButtonStyle,
  ContainerHeading,
  MainPage,
  SecondContainer,
  TextSection,
  HeadingStyle,
  ProcessImgStyle,
  Aboutparagraph,
  HeadingParagraph,
  ThirdContainer,
  ThirdContainerHeadingLocation,
  ThirdContainerHeading,
  BenefitImageLocation,
  BenefitImages,
  PaperPen,
  MapPin,
  MagGlass,
  Ribbon,
  BenefitText,
  PaperPenText,
  MapPinText,
  MagGlassText,
  RibbonText,
  FourthContainer,
  FourthContainerHeadingLocation,
  FourthContainerHeading,
  DemoHeadingLocation,
  DemoHeading,
  DemoButtonLocation,
  DemoButton,
  Aboutparagraph2,
  Aboutparagraph3,
  AboutparagraphMobile
} from './homestyle'

const Home = () => {
  return (
    <MainPage>
      <ContainerHeading>
        <Background src={require('../../images/pexels-scott-webb-2166927.jpg')}/>
        <TextLocation>
            Welcome to Seabit, the<br/>revolutionary tracking<br/>system ensuring transparency<br/>and quality in the seafood<br/>industry.
        </TextLocation>
        <ButtonLocation>
          <ButtonStyle to="/about">Learn more</ButtonStyle>
        </ButtonLocation>
      </ContainerHeading>
      <SecondContainer>
        <TextSection>
          <HeadingParagraph>
            <HeadingStyle>About Seabit</HeadingStyle>
            <Aboutparagraph> 
                At Seabit, we are committed to transparency. Our system<br/>
                supports the livelihoods of fishing communities, helps to<br/> 
                protect the oceans, and provide consumers with peace of mind.<br/>
                <br/>
                Our platform revolutionizes the seafood industry by<br/>
                providing an end-to-end traceability solution. With Seabit, you<br/> 
                can trace your seafood from the moment it is caught to the<br/> 
                moment it reaches your plate. Utilizing our technology<br/>
                ensures traceability and security, giving you peace of mind<br/> 
                that your seafood is safe and sustainably sourced.<br/><br/>
                
                Join us in our mission to revolutionize the seafood industry.<br/>
            </Aboutparagraph>
            <AboutparagraphMobile> 
                At Seabit, we are committed to transparency.<br/>
                Our system supports the livelihoods of fishing<br/> 
                communities, helps to protect the oceans, <br/>
                and provide consumers with peace of mind.<br/>
                <br/>
                Our platform revolutionizes the seafood <br/>
                industry by providing an end-to-end traceability<br/>
                solution. With Seabit, you can trace your<br/>
                seafood from the moment it is caught to the<br/> 
                moment it reaches your plate. Utilizing our<br/>
                technology ensures traceability and security,<br/>
                giving you peace of mind that your seafood is<br/>
                safe and sustainably sourced.<br/><br/>
                
                Join us in our mission to revolutionize the<br/>
                seafood industry.<br/>
            </AboutparagraphMobile>
            <Aboutparagraph3>- Bait to Plate</Aboutparagraph3>
          </HeadingParagraph>
          <ProcessImgStyle src={require('../../images/HomePageComputer.png')}/>
        </TextSection>
      </SecondContainer>
      <ThirdContainer>
        <ThirdContainerHeadingLocation>
          <ThirdContainerHeading>
            Benefit of Seabit's Comprehensive Tracking System
          </ThirdContainerHeading>
        </ThirdContainerHeadingLocation>
        <BenefitImageLocation>
          <BenefitImages>
            <PaperPen src={require('../../images/PaperPen.png')}/>
            <MapPin src={require('../../images/MapPin.png')}/>
            <MagGlass src={require('../../images/MagGlass.png')}/>
            <Ribbon src={require('../../images/Ribbon.png')}/>
          </BenefitImages>
          <BenefitText>
            <PaperPenText>Data-Driven Insights</PaperPenText>
            <MapPinText>Enhanced Traceability</MapPinText>
            <MagGlassText>Increased Transparency</MagGlassText>
            <RibbonText>Quality Assurance</RibbonText>
          </BenefitText>
        </BenefitImageLocation>
      </ThirdContainer>
      <FourthContainer>
        <FourthContainerHeadingLocation>
          <FourthContainerHeading>Ready to Get Started?</FourthContainerHeading>
        </FourthContainerHeadingLocation>
        <DemoHeadingLocation>
          <DemoHeading>
            Take a closer look at how Seabit works with a 1 on 1 demonstration
          </DemoHeading>
        </DemoHeadingLocation>
        <DemoButtonLocation>
          <DemoButton to="/demo">Request a Demo</DemoButton>
        </DemoButtonLocation>
      </FourthContainer>
    </MainPage>  
  );
};

export default Home;

