import React from 'react';

const PressRelease = () => {
  return (
    <div
      style={{
      }}
    >
      <h1>Press Release</h1>
    </div>
  );
};

export default PressRelease;
