import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AboutPage = styled.div`

`;
export const PageBackground = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
export const AboutContainer1 = styled.div`
  margin: 3% auto;
  position: relative;
  display:flex;
  height: 170px;
  width: 1024px;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const AboutContainer2 = styled.div`
  margin: 3% auto;
  display:flex;
  height: 700px;
  width: 1024px;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const AboutContainer3 = styled.div`
  margin: 50px auto;
  display:flex;
  height: 550px;
  width: 1024px;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const ParagraphContainer = styled.div`
  display: inline-block;

  @media screen and (max-width: 1024px) {
    width: 45%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 45%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 45%;
    height: auto;
  }
`;

export const AboutHeading = styled.div`
  color: #316c9c;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 32px;

  }
  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const AboutTeamHeading = styled.div`
  color: #316c9c;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 20px;
  margin-bottom: -30px;

  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const InitialParagraph = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  text-indent: 20px;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const AboutParagraph2 = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  width: 480px;
  text-indent: 20px;
  margin: 3% auto;
  position: relative;
  display:flex;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: auto;
  }
`;

export const AboutParagraph3 = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  width: 480px;
  text-indent: 20px;
  margin: 8% auto;
  position: relative;
  display:flex;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: auto;
  }
`;

export const AboutParagraph4 = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  width: 480px;
  text-indent: 20px;
  margin: 3% auto;
  position: relative;
  display:flex;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: auto;
  }
`;

export const ImgStyle = styled.img`
  height: 550px;
  width: auto;
  float: right;
  display: flex;
  margin-top: 80px;
  margin-left: 120px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1024px) {
    max-width: 42%;
    height:auto;
    margin-left: 5%;
    margin: auto auto;
  }
  @media screen and (max-width: 768px) {
    max-width: 45%;
    height: auto;
    margin-left: 5%;
    margin: auto auto;
  }

  @media screen and (max-width: 512px) {
    max-width: 45%;
    height: auto;
    margin-left: 5%;
    margin: auto auto;
  }
`;

export const AboutContainer4 = styled.div`
  margin: 3% auto;
  position: relative;
  display:flex;
  height: 125px;
  width:1024px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const AboutParagraph5 = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  text-indent: 20px;
  background-color: white;
  width: 900px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: 95%;
    height: auto;
  }
`;

export const LeftContainer = styled.div`
  width: 350px;
  display: inline-block;

  @media screen and (max-width: 1024px) {
    width: 40%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 40%;
    height: 100%;
  }

  @media screen and (max-width: 512px) {
    width: 40%;
    height: 100%;
  }
`;

export const RightContainer = styled.div`
  float:right;
  display:flex;

  @media screen and (max-width: 1024px) {
    width: 58%;
    height: 100%
  }
  @media screen and (max-width: 768px) {
    width: 58%;
    height: 100%;
  }

  @media screen and (max-width: 512px) {
    width: 58%;
    height: 100%;
  }
`;

export const NameHeading = styled.div`
  color: #316c9c;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const NoaImage = styled.img`
  border-radius: 50px;
  height: 300px;
  width: auto;
  border: 8px solid #316c9c;
  display:flex;
  margin: 10px auto;
  margin-bottom:10px;

  @media screen and (max-width: 1024px) {
    max-width: 60%;
    height:auto;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 60%;
    height:auto;
  }

  @media screen and (max-width: 512px) {
    max-width: 70%;
    height:auto;
  }
`;

export const AboutNoa = styled.p`
  width: 600px;
  display:flex;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 20px;
  margin-top: 35px;
  margin-left:40px;
  text-indent: 20px;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
    margin: auto auto;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
    margin: auto auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: auto;
    margin: auto auto;
  }
`;
export const AboutKai = styled.p`
  width: 600px;
  display:flex;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 20px;
  margin-top: 35px;
  margin-left:40px;
  text-indent: 20px;
  background-color: white;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: auto;
    margin: auto auto;

  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
    margin: auto auto;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    width: auto;
    margin: auto auto;
  }
`;

export const AboutSub1 = styled.div`
  height: auto;
`;

export const AboutSub2 = styled.div`
  height: auto;
`;

export const SocialLogo1 = styled.img`
    width:50px;
    height: auto;

    @media screen and (max-width: 1024px) {
 
    }
  
    @media screen and (max-width: 768px) {
      width: 45px;
      height: auto;
    }
  
    @media screen and (max-width: 512px) {
      width: 40px;
      height: auto;
    }
`;

export const SocialLogo2 = styled.img`
    width:50px;
    height: auto;
    
    @media screen and (max-width: 1024px) {
 
    }
  
    @media screen and (max-width: 768px) {
      width: 45px;
      height: auto;
    }
  
    @media screen and (max-width: 512px) {
      width: 40px;
      height: auto;
    }
`;

export const SocialLocation = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const Social1Link1 = styled(Link)`

`;

export const Social1Link2 = styled(Link)`

`;

export const Social2Link1 = styled(Link)`

`;

export const Social2Link2 = styled(Link)`

`;