import React from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavImg,
  DropdownContainer,
  DropDownList,
  MainNavCon,
  DropDownListText
} from './navstyle'
import {useSharedContext } from '../States/SharedContext';


const Navbar = () => {

  const { isMenuOpen, toggleMenu } = useSharedContext();
  
  console.log('Navbar - isMenuOpen:', isMenuOpen);

  const collapseMenu = () => {
    if (isMenuOpen) {
      toggleMenu();
    }
  };
  return (
    <MainNavCon >
        <Nav isMenuOpen = {isMenuOpen} >
          <NavLink to='/'>
            <NavImg src={require('../../images/Only_Seabit_Text.png')} alt='logo' />
          </NavLink>
          <Bars onClick={toggleMenu}/>  
              <DropdownContainer open={isMenuOpen}>
                <DropDownList>
                  <DropDownListText to="/" onClick={collapseMenu} >Home</DropDownListText>
                </DropDownList>
                <DropDownList >
                  <DropDownListText to="/about" onClick={collapseMenu} >About</DropDownListText>
                </DropDownList>
                <DropDownList>
                  <DropDownListText to="/tracking" onClick={collapseMenu} >Tracking</DropDownListText>
                </DropDownList>
                <DropDownList>
                  <DropDownListText to="/map" onClick={collapseMenu} >Map</DropDownListText>
                </DropDownList>
                <DropDownList>
                  <DropDownListText to="/contact" onClick={collapseMenu} >Contact</DropDownListText>
                </DropDownList>
                <DropDownList>
                  <DropDownListText to="/login" onClick={collapseMenu}>Sign In</DropDownListText>
                </DropDownList>
              </DropdownContainer>
          <NavMenu>
            <NavLink to="/" activestyle>
              Home
            </NavLink>
            <NavLink to="/about" activestyle>
              About
            </NavLink>
            <NavLink to="/tracking" activestyle>
              Tracking
            </NavLink>
            <NavLink to="/map" activestyle>
              Map
            </NavLink>
            <NavLink to="/contact" activestyle>
              Contact
            </NavLink>
            <NavLink to="/profile" activestyle>
              Profile
            </NavLink>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to='/login'>Sign In</NavBtnLink>
          </NavBtn>
        </Nav>
    </MainNavCon> 
  );
};

export default Navbar;
