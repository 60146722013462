import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoginForm = styled.div`
  margin: 6% auto;
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #c9c9c9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2rem;
  width: 250px;
  height: 420px;
  background-color: white;
`;

export const LogInTitle = styled.div`
  margin-top:20px;
  padding-bottom: 20px;
  font-size: 25px;
  
`;

export const LabelStyle = styled.div`
  color: #414a4c;
`;

export const UserField = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 5px;
  gap: 5px;
`;
export const InputField = styled.input`
  border-radius: 5px;
  border: none; 
  border:solid 1px #ccc;
  padding: 10px;
`;

export const SubmitBtn = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

export const SubmitBtnAct = styled.div`
  margin-top: 5px;
  background: #4ba9ef;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
`;

export const ForgotField = styled.div`
  margin-top: 50px;
  font-size: 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ForgotLink = styled.div`
 
`;

export const SignInProblem = styled.div`
  padding-top: 10px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
`;
export const Span = styled.span`
  cursor: pointer;
  color: #316c9c;
`;
export const ProblemLink = styled.div`
 
`;

export const RedirectLink = styled(Link)`
  text-decoration: none;
`;

export const ErrorMessageDiv = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const MessageFormatDiv = styled.div`
    margin-bottom:-10px;
    
`;