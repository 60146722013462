import React from 'react';
import {
  PrivacyContainer,
  PrivacyHeading,
  Subheadings,
  IntroParagraph,
  Subheadings1,
  NumSubheading,
  ItalicSubheading,
  NumParagraph,
  CustSpan,
  Subheadings2,
  HyperlinkSpan
} from './privacystyle'
import { Link } from 'react-router-dom';

const Privacy = () => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <PrivacyContainer>
      <PrivacyHeading>Privacy Policy</PrivacyHeading>

      <Subheadings2>Last Updated: <CustSpan>10/14/2023</CustSpan> </Subheadings2>

      <Subheadings>I. Introduction</Subheadings>
      <IntroParagraph>
        At Seabit LLC, we are committed to protecting your privacy and safeguarding your personal information. This 
        Privacy Policy outlines how we collect, use, and protect the data you provide to us when using our website 
        and related services. We understand the importance of privacy and the need to maintain the confidentiality of 
        your information. 
      </IntroParagraph>
      <IntroParagraph>
        Our website is designed to help you trace products and gather relevant information. Additionally, as part of 
        our services, we may collect and analyze data to provide you with personalized insights and enhance your 
        overall experience. It is crucial for us to ensure the security and confidentiality of both the personal 
        information you share with us and our proprietary data and information. 
      </IntroParagraph>
      <IntroParagraph>
        We take proactive measures to protect our proprietary data and information from unauthorized access, use, or 
        theft. We understand the value of our intellectual property and the competitive advantage it provides us. By 
        implementing robust security protocols and industry-standard practices, we aim to maintain the integrity of our 
        proprietary data and safeguard our company's interests. 
      </IntroParagraph>
      <IntroParagraph>
        We encourage you to read this Privacy Policy carefully to understand how your information will be handled. 
        It explains the types of data we collect, the purposes for which we use that data, and the measures we 
        take to protect it. In case you have any questions or concerns about our practices, please don't hesitate to 
        contact us using the information provided at the end of this policy. 
      </IntroParagraph>
      <IntroParagraph>
        It is important to note that by using our website and services, you consent to the collection, use, and 
        disclosure of your personal information in accordance with this Privacy Policy. We may update this policy from 
        time to time to reflect changes in our practices or legal requirements, so we recommend reviewing it 
        periodically. 
      </IntroParagraph>
      <IntroParagraph>
        Please be aware that this Privacy Policy only applies to our website and services. We are not responsible for 
        the privacy practices of any third-party websites or services that may be linked to or from our platform. We 
        encourage you to review the privacy policies of those websites before providing any personal information. 
      </IntroParagraph>
      <IntroParagraph>
        By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to 
        our data collection and usage practices as described herein. We are committed to protecting your privacy and 
        ensuring a secure online experience when using our services. Thank you for choosing Seabit LLC. 
      </IntroParagraph>
      <Subheadings>II. Information Collection</Subheadings>
      <IntroParagraph>
        At Seabit LLC, we collect various types of information to provide you with our tracing services and improve 
        your overall experience on our website. We are committed to protecting the privacy and confidentiality of the 
        data you share with us. This section outlines the types of information we collect and the methods through 
        which we obtain it. 
      </IntroParagraph>


      <Subheadings1>A. Types of Information Collected</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Personal Information:</ItalicSubheading>
      <NumParagraph>
        We may collect personal information from you when you interact with our website and services. This 
        may include, but is not limited to, your name, email address, contact information, and any other information 
        you voluntarily provide. We collect this information to identify and communicate with you, fulfill your 
        requests, and provide you with personalized services.
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Product-Related Information:</ItalicSubheading>
      <NumParagraph>
        In order to trace and provide detailed information about seafood products, we may collect 
        product-related data. This may include product names, tracking information, origin details, processing 
        information, and any other relevant details associated with the products you search for or track on our 
        website. This data helps us deliver accurate and up-to-date information to enhance your product tracing 
        experience. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Proprietary Data/Information:</ItalicSubheading>
      <NumParagraph>
        As part of our operations, we may collect proprietary data and information that is essential for providing 
        our tracing services and improving our algorithms and analytics. This includes internal analytics, 
        algorithms, and other intellectual property specific to our company. We take stringent measures to 
        protect this proprietary data and information from unauthorized access, use, or theft. 
      </NumParagraph>


      <Subheadings1>B. Methods of Data Collection</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>User Input:</ItalicSubheading>
      <NumParagraph>
        We collect information directly from you when you voluntarily provide it while using our website. This may 
        include information entered during registration, product tracking, or when you communicate with us 
        through contact forms or other means. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Automated Tracking Technologies: </ItalicSubheading>
      <NumParagraph>
        We may employ various tracking technologies, such as cookies, log files, and analytics tools, to collect 
        certain information automatically. These technologies help us understand user behavior, improve our 
        website, and enhance your overall experience. They may collect information such as your IP address, 
        browser type, device information, and usage patterns. 
      </NumParagraph>


      <Subheadings1>C. Purpose of Collecting Each Type of Information</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Personal Information: </ItalicSubheading>
      <NumParagraph>
        We collect personal information to identify and communicate with you, fulfill your requests, and provide 
        personalized services. This information also helps us understand our user base and tailor our offerings to 
        better suit your needs. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Product-Related Information: </ItalicSubheading>
      <NumParagraph>
        The collection of product-related information enables us to trace and provide detailed insights about 
        seafood products, including origin, sustainability, and quality. This data helps you make informed 
        decisions and promotes transparency in the seafood industry. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Proprietary Data/Information: </ItalicSubheading>
      <NumParagraph>
        We collect proprietary data and information to improve our tracing services, algorithms, and 
        internal analytics. Protecting this proprietary information is crucial to maintaining our 
        competitive advantage and ensuring the integrity of our operations.
      </NumParagraph>
      <IntroParagraph>
        It is important to note that the collection of personal information and product-related data is necessary 
        for the provision of our services. By providing this information, you consent to its collection and use in 
        accordance with this Privacy Policy. 
      </IntroParagraph>
      <IntroParagraph>
        We do not sell, trade, or rent your personal information or product-related data to third parties for marketing 
        purposes. However, there may be instances where we share certain information with trusted service providers 
        or business partners to assist us in delivering our services. These parties are contractually obligated to 
        maintain the confidentiality and security of the information shared with them. 
      </IntroParagraph>
      <IntroParagraph>
        We comply with applicable laws and regulations governing data protection and privacy. In cases where 
        required by law or in response to a valid legal request, we may disclose personal information or other data to 
        law enforcement authorities or government agencies.
      </IntroParagraph>
      <IntroParagraph>
        Protecting our proprietary data and information is of utmost importance to us. We take comprehensive 
        security measures, including encryption, access controls, and regular security assessments, to 
        safeguard our proprietary data from unauthorized access. 
      </IntroParagraph>


      <Subheadings>III. Use of Collected Information</Subheadings>
      <IntroParagraph>
        We understand the importance of using the information we collect in a responsible and transparent manner. 
        This section outlines how we utilize the collected information to provide our tracing services, improve user 
        experience, protect proprietary data, and comply with legal obligations. 
      </IntroParagraph>

      <Subheadings1>A. How the Collected Information is Used</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Providing and Improving Website Services:</ItalicSubheading>
      <NumParagraph>
        The information we collect, including personal information and product-related data, is used to provide 
        you with our tracing services. This includes tracing and providing detailed information about seafood 
        products, such as origin, sustainability, and quality. We strive to continually enhance our services and user 
        experience based on the feedback and data we receive. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Personalizing User Experience:</ItalicSubheading>
      <NumParagraph>
        By analyzing the information we collect, we aim to personalize your experience on our website. This may 
        involve tailoring product recommendations, displaying relevant content, and optimizing the website's 
        functionality to better suit your preferences and needs. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Analyzing Product Data for Insights:</ItalicSubheading>
      <NumParagraph>
        We may analyze the aggregated and anonymized product-related data to gain insights into trends, 
        industry patterns, and consumer preferences. This analysis allows us to improve our tracing algorithms, 
        develop industry reports, and contribute to the overall advancement of the seafood industry. 
      </NumParagraph>

      <NumSubheading>4.</NumSubheading>
      <ItalicSubheading>Protecting Proprietary Data/Information:</ItalicSubheading>
      <NumParagraph>
        We utilize the collected information to safeguard our proprietary data and information from unauthorized 
        access, use, or theft. This involves implementing stringent security measures and access controls, 
        conducting regular security assessments, and employing encryption techniques to maintain the integrity 
        of our operations.
      </NumParagraph>

      <Subheadings1>B. Third-Party Access to Information</Subheadings1>
      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Sharing with Service Providers:</ItalicSubheading>
      <NumParagraph>
        We may engage trusted third-party service providers to assist us in delivering our services effectively. 
        These service providers may have access to certain personal information or product related data as 
        necessary for the performance of their duties. However, they are bound by contractual agreements to 
        handle this information confidentially and in accordance with our privacy standards. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Sharing with Business Partners:</ItalicSubheading>
      <NumParagraph>
        In certain cases, we may collaborate with business partners to enhance our services or offer 
        complementary products or services. If such collaborations involve sharing information, we will ensure 
        that appropriate safeguards are in place to protect the confidentiality and security of the shared data.
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Compliance with Legal Obligations:</ItalicSubheading>
      <NumParagraph>
        We may disclose your personal information, product-related data, or other collected information if required 
        to do so by law or if we believe that such disclosure is necessary to comply with a legal obligation, protect 
        our rights, or ensure the safety of our users or others. This may include sharing information in response to 
        a court order, subpoena, or other lawful request.
      </NumParagraph>

      <IntroParagraph>
        It is important to note that while we take precautions to protect your information, no data transmission or 
        storage method can be guaranteed to be 100% secure. We strive to maintain reasonable physical, technical, 
        and administrative safeguards to prevent unauthorized access, use, or disclosure of your information. 
        However, we cannot guarantee absolute security. 
      </IntroParagraph>
      <IntroParagraph>
        We do not sell, trade, or rent your personal information or product-related data to third parties for marketing 
        purposes without your explicit consent. 
      </IntroParagraph>
      <IntroParagraph>
        By using our website and services, you acknowledge and consent to the utilization of your information as 
        described in this Privacy Policy. We will retain your information for as long as necessary to fulfill the purposes 
        outlined above, unless a longer retention period is required or permitted by law. 
      </IntroParagraph>
      <IntroParagraph>
        Please note that the use of cookies and similar technologies is addressed in a separate section 
        here: <HyperlinkSpan onClick={scrollToTop}><Link to="/cookiespolicy">Cookies Policy</Link></HyperlinkSpan>. 
      </IntroParagraph>

      <Subheadings>IV. Data Security</Subheadings>
      <IntroParagraph>
        Protecting the security and confidentiality of your information is of paramount importance to us at Seabit LLC. 
        We have implemented robust security measures to safeguard your personal information, product-related 
        data, and proprietary information from unauthorized access, use, or theft. This section outlines our data 
        security practices and the steps we take to ensure the integrity of your information. 
      </IntroParagraph>

      <Subheadings1>A. Description of Security Measures</Subheadings1>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Encryption of Sensitive Data:</ItalicSubheading>
      <NumParagraph>
        We employ industry-standard encryption technologies to protect sensitive data transmitted between 
        your device and our servers. This includes the use of Secure Socket Layer (SSL) or Transport Layer 
        Security (TLS) encryption protocols to establish a secure connection.
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Regular Security Assessments:</ItalicSubheading>
      <NumParagraph>
        We conduct regular security assessments to identify and address potential vulnerabilities. These 
        assessments may involve internal audits, third-party security audits, penetration testing, and vulnerability 
        scanning to ensure the ongoing security of our systems. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Access Controls and Authentication:</ItalicSubheading>
      <NumParagraph>
        We enforce strict access controls and authentication mechanisms to limit access to your information. 
        This includes using strong passwords, multi-factor authentication, and role-based access controls to 
        ensure that only authorized individuals have access to sensitive data. 
      </NumParagraph>

      <Subheadings1>B. Procedures for Data Breach Notification and Response</Subheadings1>
      <IntroParagraph>
        In the event of a data breach or unauthorized access to your information, we have implemented 
        procedures to promptly respond and mitigate any potential harm. These procedures include: 
      </IntroParagraph>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Incident Response Plan:</ItalicSubheading>
      <NumParagraph>
        We maintain an incident response plan that outlines the steps to be taken in the event of a data breach. 
        This plan includes identifying and containing the breach, assessing the impact, notifying affected 
        individuals, and taking appropriate remedial actions. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Notification:</ItalicSubheading>
      <NumParagraph>
        If we determine that a data breach poses a significant risk of harm to your rights and freedoms, we will 
        notify you in accordance with applicable laws and regulations. We will provide clear and timely 
        information about the breach, the types of information affected, and the measures we are taking to 
        mitigate the impact. 
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Remedial Actions: </ItalicSubheading>
      <NumParagraph>
        In the event of a data breach, we will take prompt action to contain and remediate the situation. This may 
        involve implementing additional security measures, cooperating with relevant authorities, and conducting 
        internal investigations to prevent similar incidents from occurring in the future. 
      </NumParagraph>

      <Subheadings1>C. User Responsibilities for Safeguarding Their Own Data </Subheadings1>
      <IntroParagraph>
        While we take comprehensive measures to protect your information, it is essential for you to take 
        responsibility for safeguarding your own data. We recommend the following best practices: 
      </IntroParagraph>

      <NumSubheading>1.</NumSubheading>
      <ItalicSubheading>Use Strong Passwords:</ItalicSubheading>
      <NumParagraph>
        Choose unique and strong passwords for your account and avoid using the same password across 
        multiple platforms. Regularly update your passwords to minimize the risk of unauthorized access. 
      </NumParagraph>

      <NumSubheading>2.</NumSubheading>
      <ItalicSubheading>Exercise Caution with Phishing Attempts:</ItalicSubheading>
      <NumParagraph>
        Be cautious of suspicious emails, messages, or requests for personal information. Avoid clicking on 
        unfamiliar links or providing sensitive information unless you are certain of the source's legitimacy.
      </NumParagraph>

      <NumSubheading>3.</NumSubheading>
      <ItalicSubheading>Keep Software Updated:</ItalicSubheading>
      <NumParagraph>
        Ensure that your operating system, web browser, and security software are up to date with the latest 
        patches and updates. Regularly installing updates helps protect against known vulnerabilities. 
      </NumParagraph>

      <NumSubheading>4.</NumSubheading>
      <ItalicSubheading>Log Out and Secure Your Device: </ItalicSubheading>
      <NumParagraph>
        Always log out of your account after each session, especially when using shared or public 
        devices. Additionally, secure your personal devices with strong passwords, biometric a
        uthentication, or other security features. 
      </NumParagraph>

      <IntroParagraph>
        Please note that while we take reasonable precautions to protect your information, no method of data 
        transmission or storage can be guaranteed to be 100% secure. The Internet is not entirely secure, and we 
        encourage you to be vigilant and take appropriate measures to protect your information. 
      </IntroParagraph>
      <IntroParagraph>
        By using our website and services, you acknowledge and accept the inherent risks associated with data 
        transmission over the Internet. We disclaim any liability for any unauthorized access, disclosure, or 
        loss of your information due to factors beyond our reasonable control. 
      </IntroParagraph>
      <IntroParagraph>
        If you have any questions or concerns about our data security practices, please contact us using the 
        information provided at the end of this Privacy Policy. 
      </IntroParagraph>

      <Subheadings>V. User Rights</Subheadings>
      <IntroParagraph>
        At Seabit LLC, we respect your rights regarding the privacy and protection of your personal information. This 
        section outlines the rights you have as a user of our website and services, and how you can exercise those 
        rights. We are committed to ensuring that you have control over your information and can make informed 
        decisions about its collection, use, and disclosure. 
      </IntroParagraph>

      <Subheadings1>A. Access and Correction of Personal Information </Subheadings1>
      <IntroParagraph>
        You have the right to access and correct any personal information we hold about you. If you believe that the 
        information we have is inaccurate, incomplete, or outdated, you may request to have it updated or corrected. 
        To exercise this right, please contact us using the information provided at the end of this Privacy Policy. We will 
        make reasonable efforts to respond to your request promptly and in accordance with applicable laws. 
      </IntroParagraph>

      <Subheadings1>B. Data Portability </Subheadings1>
      <IntroParagraph>
        Subject to applicable laws, you have the right to request a copy of the personal information you have provided 
        to us in a structured, commonly used, and machine-readable format. This allows you to transfer that 
        information to another organization or service provider of your choice. To make such a request, please contact 
        us using the information provided at the end of this Privacy Policy.
      </IntroParagraph>

      <Subheadings1>C. Withdrawal of Consent</Subheadings1>
      <IntroParagraph>
        If you have provided your consent for the collection, use, or disclosure of your personal information, you have 
        the right to withdraw that consent at any time. To withdraw your consent, please contact us using the 
        information provided at the end of this Privacy Policy. Please note that withdrawing consent may affect our 
        ability to provide certain services to you, and we will inform you of the consequences of your withdrawal. 
      </IntroParagraph>

      <Subheadings1>D. Data Retention and Deletion </Subheadings1>
      <IntroParagraph>
        We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy 
        Policy, unless a longer retention period is required or permitted by law. If you wish to have your personal 
        information deleted from our systems, please contact us using the information provided at the end of this 
        Privacy Policy. We will make reasonable efforts to honor your request, subject to any legal obligations or 
        legitimate business interests that may require us to retain certain information.
      </IntroParagraph>

      <Subheadings1>E. Marketing Communications </Subheadings1>
      <IntroParagraph>
        You have the right to opt out of receiving marketing communications from us. If you no longer wish to receive 
        promotional emails, newsletters, or other marketing materials from us, you can unsubscribe by following the 
        instructions provided in the communication or by contacting us using the information provided at the end of 
        this Privacy Policy. 
      </IntroParagraph>

      <Subheadings1>F. Complaints and Contact Information</Subheadings1>
      <IntroParagraph>
        If you have any concerns or complaints regarding our privacy practices or the handling of your personal 
        information, please contact us using the information below. We take such matters seriously and will make 
        every effort to address and resolve your concerns. 
      </IntroParagraph>
      <IntroParagraph>Seabit LLC </IntroParagraph>
      <IntroParagraph>contact@seabit.xyz </IntroParagraph>
      <IntroParagraph>
        We strive to respond to all inquiries and requests in a timely manner and in accordance with applicable laws 
        and regulations. If you are not satisfied with our response or believe that your rights have been infringed, you 
        may have the right to lodge a complaint with the appropriate supervisory authority in your jurisdiction. 
      </IntroParagraph>
      <IntroParagraph>
        Please note that certain legal requirements or exemptions may impact your rights as described above. We will 
        inform you if any such limitations apply to your specific request. 
      </IntroParagraph>


      <Subheadings>VI. Third-Party Websites</Subheadings>
      <IntroParagraph>
        Our website may contain links or references to third-party websites, products, or services that are not operated 
        or controlled by Seabit LLC. This section explains our approach to third-party websites and how your 
        information is handled when you navigate to these external sites. 
      </IntroParagraph>

      <Subheadings1>A. External Links </Subheadings1>
      <IntroParagraph>
        Our website may include links to third-party websites for your convenience and reference. Please note that 
        we do not endorse or have control over the privacy practices or content of these websites. This Privacy Policy 
        applies solely to our website, and we encourage you to review the privacy policies of any third-party websites 
        you visit. 
      </IntroParagraph>

      <Subheadings1>B. Third-Party Services and Applications</Subheadings1>
      <IntroParagraph>
        We may integrate third-party services or applications into our website to enhance its functionality or provide 
        additional features. These third-party services may have their own privacy policies and terms of use, and your 
        interactions with them are governed by their respective policies. We are not responsible for the privacy 
        practices or actions of these third-party services
      </IntroParagraph>

      <Subheadings1>C. Data Collection by Third Parties </Subheadings1>
      <IntroParagraph>
        When you access or interact with third-party websites or services through links on our website, those third 
        parties may collect information about you. This may include personal information, product-
        related data, or other information relevant to their services. We encourage you to review the privacy policies 
        of these third parties to understand how your information will be collected, used, and shared. 

      </IntroParagraph>

      <Subheadings1>D. Responsibility and Liability </Subheadings1>
      <IntroParagraph>
        While we take reasonable measures to ensure the quality and integrity of the third-party websites or services 
        we link to, we do not assume any responsibility for their actions, content, or privacy practices. Any interactions 
        you have with third-party websites or services are at your own risk, and we encourage you to exercise caution 
        and review their policies before providing any personal information or engaging in transactions. 
      </IntroParagraph>

      <Subheadings1>E. Advertisements</Subheadings1>
      <IntroParagraph>
        Our website may display advertisements from third-party advertisers or ad networks. These advertisements 
        may use cookies, web beacons, or other tracking technologies to collect information about your activities on 
        our website and other websites to provide targeted advertising based on your interests. We do not have control
         over the practices of these third-party advertisers, and their collection and use of your information are 
         governed by their respective privacy policies. 
      </IntroParagraph>
      <IntroParagraph>
        We recommend reviewing the privacy policies of these third-party advertisers to understand their practices 
        and exercise any opt-out options they may provide. 
      </IntroParagraph>
      <IntroParagraph>
        Please note that we do not sell, trade, or rent your personal information to third parties for their 
        marketing purposes without your explicit consent. 
      </IntroParagraph>
      <IntroParagraph>
        If you have any questions or concerns regarding the practices of third-party websites or services linked to 
        our website, please contact us using the information provided at the end of this Privacy Policy. 
      </IntroParagraph>

      <Subheadings>VII. Changes to the Privacy Policy </Subheadings>
      <IntroParagraph>
        We may update or modify this Privacy Policy from time to time to reflect changes in our practices, legal 
        requirements, or industry standards. This section explains how we handle changes to the Privacy Policy and 
        how we will notify you of any material updates. 
      </IntroParagraph>

      <Subheadings1>A. Notification of Changes</Subheadings1>
      <IntroParagraph>
        When we make changes to this Privacy Policy, we will update the "Last Updated" date at the beginning of the 
        policy. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, 
        and protect your information. If we make any material changes that affect your rights or significantly alter our 
        data processing practices, we will provide notice in a manner consistent with legal requirements. 
      </IntroParagraph>

      <Subheadings1>B. Obtaining Your Consent</Subheadings1>
      <IntroParagraph>
        If required by applicable laws, we will seek your consent for any material changes to this Privacy Policy that 
        may impact the way we collect, use, or share your personal information. We will provide you with an 
        opportunity to review the revised Privacy Policy and make an informed decision about whether to continue 
        using our website and services.
      </IntroParagraph>

      <Subheadings1>C. Continued Use of the Website</Subheadings1>
      <IntroParagraph>
        By continuing to access or use our website and services after any changes to this Privacy Policy, you signify 
        your acceptance of the revised policy. If you do not agree with the terms of this Privacy Policy or any updates 
        made to it, please discontinue using our website and services. 
      </IntroParagraph>

      <Subheadings1>D. Retaining Previous Versions</Subheadings1>
      <IntroParagraph>
        We retain previous versions of this Privacy Policy in an archive for your reference. You may request access to 
        previous versions by contacting us using the information provided at the end of this Privacy Policy. 
      </IntroParagraph>
      <IntroParagraph>
        We recommend that you check this Privacy Policy regularly to review any updates or changes. Your 
        continued use of our website and services signifies your acceptance of any changes made to this Privacy 
        Policy. 
      </IntroParagraph>
      <IntroParagraph>
        If you have any questions or concerns about the changes to this Privacy Policy, please contact us using 
        the information provided at the end of this Privacy Policy. 
      </IntroParagraph>


      <Subheadings>VIII. Contact Information</Subheadings>
      <IntroParagraph>
        If you have any questions, concerns, or inquiries regarding this Privacy Policy or our privacy practices, please 
        contact us using the following information: 
      </IntroParagraph>
      <IntroParagraph>Seabit LLC </IntroParagraph>
      <IntroParagraph>contact@seabit.xyz </IntroParagraph>
      <IntroParagraph>
        We strive to respond to all inquiries and requests in a timely and efficient manner. If you need further 
        clarification on any aspect of this Privacy Policy or require assistance regarding the handling of your personal 
        information, our team is here to assist you. 
      </IntroParagraph>
      <IntroParagraph>
        Please note that due to the nature of information transmitted over the internet, we cannot guarantee the 
        confidentiality or security of any communication sent to us through email or online forms. We encourage you 
        to avoid including sensitive or confidential information in your communications with us. 
      </IntroParagraph>
      <IntroParagraph>
        If you prefer to contact us by mail or phone, please use the provided contact details above. We will make 
        every effort to address your concerns and resolve any issues related to your privacy and data protection. 
      </IntroParagraph>
      <IntroParagraph>
        Please allow reasonable time for us to investigate and respond to your inquiry. In some cases, we may require 
        additional information from you to verify your identity or better understand the nature of your request. 
      </IntroParagraph>
      <IntroParagraph>
        We value your privacy and are committed to maintaining the security and confidentiality of your information. If 
        you believe that we have not adequately addressed your privacy concerns or if you are not satisfied with our 
        response, you may have the right to lodge a complaint with the appropriate supervisory authority in your 
        jurisdiction.
      </IntroParagraph>
      <IntroParagraph>
        Please note that this Privacy Policy only applies to our website and services and does not govern the practices 
        of third-party websites or services that may be linked to or accessed through our website. We encourage you to 
        review the privacy policies of those third parties for information on their privacy practices. 
      </IntroParagraph>
      <IntroParagraph>
        Thank you for taking the time to read and understand our Privacy Policy. We are dedicated to 
        protecting your privacy and providing a secure and trustworthy experience on our website. 
      </IntroParagraph>
      <IntroParagraph>Seabit LLC</IntroParagraph>


    </PrivacyContainer>
  );
};

export default Privacy;
