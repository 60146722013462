import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home/home';
import About from './pages/About/about';
import Contact from './pages/Contact/contact';
import Login from './pages/Login/login';
import Footer from './components/Footer/footer';
import WhitePaper from './pages/Whitepaper/whitepaper';
import Blogs from './pages/Blogs/blogs';
import FAQ from './pages/FAQ/faq';
import PressRelease from './pages/PressReleases/pressrelease';
import Privacy from './pages/Privacy/privacy';
import TermsConditions from './pages/TermsConditions/termsconditions';
import Demo from './pages/Demo/demo';
import Restricted from './pages/PlaceholderPermission/placeholderpermission';
import CopyrightInfringement from './pages/CopyrightInfringementPolicy/copyrightinfringement';
import CookiesPolicy from './pages/CookiesPolicy/cookiespolicy';
import { SharedContextProvider } from './components/States/SharedContext';


function App() {

  return (
  <div className= "background">
    <Router>
      <SharedContextProvider>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/blog' element={<Blogs />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/pressrelease' element={<PressRelease />} />
          <Route path='/whitepaper' element={<WhitePaper />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/termsconditions' element={<TermsConditions />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='/placeholderpermission' element={<Restricted />} />
          <Route path='/copyrightinfringement' element={<CopyrightInfringement />} />
          <Route path='/cookiespolicy' element={<CookiesPolicy />} />
        </Routes>
        <Footer/>
      </SharedContextProvider>
    </Router>
  </div>

  );
}

export default App;
