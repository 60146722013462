import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  /*background: #d3d3d3;*/
  background: #316c9c;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
  position: relative;
  margin-bottom: 3%;

  @media screen and (max-width: 1024px) {
    margin-bottom: ${props => (props.isMenuOpen ? '320px' : '0')};
    transition: margin-bottom 0.3s ease; 
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #010606;
  
`;

export const NavImg = styled.img`
  width: 200px;
  margin-right: 14px;
  margin-left: -14px;

  @media screen and (max-width: 1024px) {
    margin-left: 5%;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 100px;

  /* Third Nav */
  /*width: 100vw;*/
  /*white-space: nowrap;*/

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -12px;

  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 20px;
  background: #4ba9ef;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* Second Nav */
  /* margin-left: 24px;*/

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const DropdownContainer = styled.div`
  display: none;
  flex-direction: column;
  background-color: #316c9c;
  color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0px;
  z-index: 1;
  text-align: center;
  max-height: ${props => (props.open ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;

  @media (max-width: 1024px) {
    display: flex;
    position: absolute;
  }
`;

export const DropDownList = styled.div`
  margin: 10px 0;
`;

export const DropDownUL = styled.ul`
  list-style: none;
  
`;

export const DropDownLI = styled.li`
  padding: 0px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const MainNavCon = styled.div`

`;

export const DropDownListText = styled(Link)`
  font-size: 22px;
  text-decoration: none;
  color: #fff;
`;