import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainPage = styled.div`

`;

export const HeadingParagraph = styled.div`
  display: inline-block;
  width:590px;

  @media screen and (max-width: 1024px) {
    width:400px;
  }
  
  @media screen and (max-width: 768px) {
    width:260px;
  }

  @media screen and (max-width: 512px) {
    width:210px;
  }
`;

export const Aboutparagraph = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 21px;
  display: inline-block;
  background-color: white;
  
  @media screen and (max-width: 1024px) {
    display: none;
  }

`;

export const AboutparagraphMobile = styled.p`
  display: none;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    display: inline-block;
    background-color: white;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    display: inline-block;
    background-color: white;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    display: inline-block;
    background-color: white;
  }

`;
export const Aboutparagraph2 = styled.span`
`;
export const Aboutparagraph3 = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 21px;
  text-align: right;
  margin-right: 40px;
  margin-top: 0px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const SecondContainer = styled.div`
  height: 440px;
  width:1024px;
  margin: 3% auto;
  padding-top:10px;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }
  
`;

export const HomeContactFields = styled.div`
  display: flex;
`;

export const FormatContactFields = styled.div`

`;
export const HomeContactNameFirst = styled.input`
  background-color: transparent;
  padding: 10px;
  position: absolute;
  left: 25%;
  top: 55%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #000000;
  outline: 1px dotted #000000;
  outline-offset: -3px;
`;

export const HomeContactNameLast = styled.input`
  background-color: transparent;
  padding: 10px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #000000;
  outline: 1px dotted #000000;
  outline-offset: -3px;
`;

export const HomeContactEmail = styled.input`
  background-color: transparent;
  padding: 10px;
  position: absolute;
  left: 75%;
  top: 55%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #000000;
  outline: 1px dotted #000000;
  outline-offset: -3px;
`;

export const HomeContactFirstLabel = styled.label`
  position: absolute;
  left: 20%;
  top: 48%;
  transform: translateX(-50%);
  z-index: 1;
  font-weight: bold;
`;

export const HomeContactLastLabel = styled.label`
  position: absolute;
  left: 45%;
  top: 48%;
  transform: translateX(-50%);
  z-index: 1;
  font-weight: bold;
`;

export const HomeContactEmailLabel = styled.label`
  position: absolute;
  left: 68%;
  top: 48%;
  transform: translateX(-50%);
  z-index: 1;
  font-weight: bold;
`;

export const TextSection = styled.div`
  font-size: 18px;
`;

export const ProcessImgStyle = styled.img`
  width: auto;
  float: right;
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    max-width: 42%;
    height:auto;
  }
`;

export const HeadingStyle = styled.div`
  color: #316c9c;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const Background= styled.img`
  height: 500px;
  background-size: cover
  display: block; 
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    height:auto;
  }
`;

export const TextLocation = styled.div`
  position: absolute;
  left: 25%;
  top: 8%;
  transform: translateX(-50%);
  padding:10px;
  color: #fff;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.35;

  @media screen and (max-width: 1024px) {
    font-size: 25px;
    left: 25%;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    left: 25%;
  }

  @media screen and (max-width: 512px) {
    font-size: 14px;
    left: 25%;
  }

`;

export const ButtonLocation = styled.div`
  position: absolute;
  left: 16%;
  top: 54%;
  transform: translateX(-50%);
  padding:10px;

  @media screen and (max-width: 1024px) {
    left: 18%;
    top: 60%;
  }
  @media screen and (max-width: 768px) {
    left: 18%;
    top: 62%;
  }

  @media screen and (max-width: 512px) {
    left: 18%;
    top: 60%;
  }
`;

export const ContainerHeading = styled.div`
  margin: 3% auto;
  position: relative;
  display:flex;
  justify-content: center;
  height: 500px;
  width:1024px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;

export const ButtonStyle = styled(Link)`
  border-radius: 22px;
  /*background: #4ba9ef;*/
  background: linear-gradient(to right, #5acdf5, #1e94eb);
  padding: 10px 14px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  justify-content: center;
  width: 200px;
  font-size: 28px;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  /*font-weight: bold;*/

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    width: 140px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 100px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    width: 70px;
  }
`;

export const ThirdContainer = styled.div`
  height: 350px;
  width:1024px;
  display:flex;
  flex-direction: column;
  margin: 3% auto;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const ThirdContainerHeading = styled.div`
  color: #316c9c;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const ThirdContainerHeadingLocation =styled.div`
  margin-top: 20px;  
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 512px) {
    margin-bottom: 30px;
  }
`;

export const BenefitImageLocation = styled.div`

@media screen and (max-width: 512px) {

}

`;

export const BenefitImages = styled.div`

  @media screen and (max-width: 512px) {
    width: 270px;
    height: 50px;
    display: flex;
    align-items: center;
}
`;

export const PaperPen = styled.img`
  width: auto;
  height: 110px;
  margin-left: 80px;

  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
    margin-left: 12%;
  }

  @media screen and (max-width: 512px) {
    width: auto;
    height: 50px;
    margin-left: 0%;
  }
`;

export const MapPin = styled.img`
  width: auto;
  height: 110px;
  margin-left: 90px;

  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
  }

  @media screen and (max-width: 512px) {
    width: auto;
    height: 50px;
    margin-left: 55px;
  }
`;

export const MagGlass = styled.img`
  width: auto;
  height: 110px;
  margin-left: 150px;

  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
  }

  @media screen and (max-width: 512px) {
    width: auto;
    height: 50px;
    margin-left: 55px;
  }
`;

export const Ribbon = styled.img`
  width: auto;
  height: 110px;
  margin-left: 170px;

  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
  }

  @media screen and (max-width: 512px) {
    width: auto;
    height: 50px;
    margin-left: 55px;
  }
`;

export const BenefitText = styled.div`
  flex-direction: row;
  display: flex;
`;

export const PaperPenText = styled.div`
  margin: 30px 50px;
  color: #316c9c;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    margin: 30px 0px;
  }
`;

export const MapPinText = styled.div`
  margin: 30px 50px;
  color: #316c9c;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    margin: 30px 0px;
  }
`;

export const MagGlassText = styled.div`
  margin: 30px 50px;
  color: #316c9c;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    margin: 30px 0px;
  }
`;

export const RibbonText = styled.div`
  margin: 30px 50px;
  color: #316c9c;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    margin: 30px 0px;
  }
`;

export const FourthContainer = styled.div`
  height: 250px;
  width:1024px;
  margin: 3% auto;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
  }
`;

export const FourthContainerHeadingLocation = styled.div`
  position: relative;
  display:flex;
  justify-content: center;
`;

export const FourthContainerHeading = styled.div`
  color: #316c9c;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const DemoHeadingLocation = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 512px) {
    margin-top: 20px;
  }
`;

export const DemoHeading = styled.div`
  color: #316c9c;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 512px) {
    font-size: 15px;
  }
`;

export const DemoButtonLocation = styled.div`
  margin-top: 50px;
  position: relative;
  display:flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
 
  }

  @media screen and (max-width: 512px) {
    margin-top: 20px;
  }
`;

export const DemoButton = styled(Link)`
  border-radius: 28px;
  /*background: #4ba9ef;*/
  background: linear-gradient(to right, #5acdf5, #1e94eb);
  padding: 18px 14px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  justify-content: center;
  width: 300px;
  font-size: 24px;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  /*font-weight: bold;*/

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: 120px;
    padding: 16px 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
    width: 100px;
    padding: 14px 10px;
  }
`;

