import React, { createContext, useContext, useState } from 'react';

const SharedContext = createContext();

export const useSharedContext = () => useContext(SharedContext);

export function SharedContextProvider({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  return (
    <SharedContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </SharedContext.Provider>
  );
}