import { useState } from "react";
import styled from 'styled-components';

const formatphonenumber = (value, previousValue) => {
  
  if (!value) return value;

  const phonenumber = value.replace(/[^\d]/g,'');

  const phonenumberlength = phonenumber.length;

  if(!previousValue || value.length > previousValue.length) {
    
    if (phonenumberlength < 4) return phonenumber;

    if(phonenumberlength < 7){
      return `(${phonenumber.slice(0,3)}) ${phonenumber.slice(3)}`;
    } 

    return `(${phonenumber.slice(0,3)}) ${phonenumber.slice(3,6)}-${phonenumber.slice(6,10)}`;

  }
};

export default function PhoneFormat() {
  const[inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    const formattedphonenumber = formatphonenumber(e.target.value);
    setInputValue(formattedphonenumber);
  }

  return <StyledPhone onChange={(e) => handleInput(e)} value= {inputValue}/>
};

export const StyledPhone = styled.input`
  padding: 8px;
  border-radius: 0px 5px 5px 0px;
  border:solid 1px #ccc;
  width: 255px;
  

  @media screen and (max-width: 1024px) {
    width:100%;
    padding:6px;
    font-size:16px;
  }

  @media screen and (max-width: 768px) {
    width:100%;
    padding: 6px;
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    width:100%;
    padding: 4px;
    font-size: 10px;
  }
`;