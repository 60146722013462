import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const TermsContainer = styled.div`
    width: 1024px;
    display: flex;
    margin: 3% auto;
    display:flex;
    flex-direction: column;
`;

export const TermsHeading = styled.div`
    font-size: 34px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 50px;
`;

export const Subheadings = styled.div`
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-top: 20px;
`;

export const BoldLetterSpan = styled.span`
    font-weight: bold;
`;

export const IntroParagraph = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.35;
`;

export const Subheadings1 = styled.div`
    margin-top: 10px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const Subheadings2 = styled.div`
    margin-top: 10px;
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 30px;
`;

export const CustSpan = styled.span`
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
`;

export const HyperlinkSpan = styled.span`
    text-decoration: underline;
    color: blue;
    cursor: pointer;
`;

export const HyperLinkStyle = styled(Link)`
    text-decoration: underline;
    color: blue;
    cursor: pointer;
`;

export const NumSubheading = styled.div`
    font-family: Arial, Helvetica, sans-serif;

`;

export const ItalicSubheading = styled.div`
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 30px;
    margin-bottom: 5px;
`;

export const NumParagraph = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 30px;
    line-height: 1.35;
    margin-bottom: 20px;
`;

export const CustomBulletPoint1 = styled.ul`

`;

export const CustomBulletPoint2 = styled.li`
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 30px;
    line-height: 1.35;
    margin-bottom: 20px;
`;
