import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RocketContainer = styled.div`
  display: block;
  height:225px;
  position: relative;
  margin-top: 80px;
`;

export const FooterContainer1 = styled.div`
  margin-top:150px;
  background-color: #316c9c;
  height:225px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left:0;
  right:0;
  @media screen and (max-width: 1024px) {
    height: 225px;
  }
`;

export const FooterMain = styled.div`
  width: 1024px;
  display: block;
  margin: 0 auto;
  height: 200px;
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 5px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const FooterText = styled.div`
  margin-bottom: 12px;
  color: #fff;  
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FooterColumn1 = styled.div`
  display: inline-block;
  margin-right: 80px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FooterColumn = styled.div`
  display: inline-block;
  margin: 20px 55px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const HeadingFooter = styled.div`
  color: #fff;  
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const FooterPrivacy = styled(Link)`
  color: #fff;  
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #010606;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FooterLogo = styled.img`
  margin-bottom: 10px;
  width: 200px;
`;

export const ColumnFooterText = styled.div`
  margin-bottom: 30px;
  margin-left: 20px;
  color: #fff;  
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    margin-left: 10px;
  }
`;

export const FooterTextTest =styled(Link)`
  color: #fff;  
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 12px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #010606;
  }

`;

export const FooterPrivacyLocation = styled.div`
  width: 1024px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-decoration: none;
`;

export const MailImage = styled.img`
    width:auto;
    height: 30px;

    @media screen and (max-width: 1024px) {
      width:auto;
      height: 24px;
    }
`;

export const LocationImage = styled.img`
  width:auto;
  height: 30px;

  @media screen and (max-width: 1024px) {
    width:auto;
    height: 20px;
  }
`;

export const LocationLine = styled.div`
  display: flex;

  @media screen and (max-width: 1024px) {
    display: flex;
    margin-bottom: 13px;
  }
`;

export const EmailLine =styled.div`
  display: flex;

`;

export const CloudLocation = styled.div`

`;

export const CloudImage = styled.img`

`;

export const LogoLink = styled(Link)`

`;

export const MobileElementContainer = styled.div`
display: none;

@media screen and (max-width: 1024px) {
  display: block;
  height: 200px;
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
}
`;

export const FooterColumn1Mobile = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: inline-block;
    margin-top: 10px;
  }
`;

export const FooterColumnMobile = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: inline-block;
    margin-left: 50px;
    margin-top: 10px;
  }
`;

export const FooterPrivacyMobile = styled(Link)`

  @media screen and (max-width: 1024px) {
    color: #fff;  
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 5px;
    margin-left: 5px;
    text-decoration: none;
    &:hover {
      transition: all 0.2s ease-in-out;
      color: #010606;
    }
  }
`;

export const FooterPrivacyLocationMobile = styled.div`
  display: none;

@media screen and (max-width: 1024px) {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-decoration: none;
  }
`;

export const MobileElementSubContainer = styled.div`

@media screen and (max-width: 1024px) {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-decoration: none;
  width: 350px;
  }
`;

export const FooterLogoMobile = styled.img`
  margin-bottom: 25px;
  width: 155px;
  height: auto;
`;