import React from 'react';
import {
  MainContainer,
  SubContainer1,
  SubContainer2,
  SubContainer1Heading,
  SubContainer1MinorMeading,
  Heading1,
  DemoParagraph,
  SubHeading,
  Line1Main,
  Line2Main,
  Line3Main,
  Checkmark,
  Oneline,
  PrivacyNote,
  NameSection,
  NameLabel,
  EmailSection,
  EmailLabel,
  PhoneSection,
  PhoneLabel,
  CompanySection,
  CompanyLabel,
  CountrySection,
  CountryLabel,
  QuestionCommentSection,
  QuestionCommentLabel,
  NameInput,
  EmailInput,
  PhoneInput,
  CompanyInput,
  CountrySelect,
  QuestionCommentBox,
  SendRequestSection,
  SendRequestBtn,
  SubContainer2Box

} from './demostyle'

const Demo = () => {
  return (
    <MainContainer>
      <SubContainer1>
        <SubContainer1Heading>
          <Heading1>Request a Demo</Heading1>
          <DemoParagraph>
          We look forward to showing you how our
          platform allows you to optimize data,
          enhance traceability and increase the
          overall transparency of your company.
          </DemoParagraph>
          <SubHeading>What can I expect?</SubHeading>
          <Line1Main>
            <Checkmark src={require('../../images/Check_Transparent.png')}/>
            <Oneline>
              A brief conversation to assess what Seabit can do to help your company.
            </Oneline>
          </Line1Main>
          <Line2Main>
            <Checkmark src={require('../../images/Check_Transparent.png')}/>
            <Oneline>
              Live product demo.
            </Oneline>
          </Line2Main>
          <Line3Main>
            <Checkmark src={require('../../images/Check_Transparent.png')}/>
            <Oneline>
              Discuss key performance indicators to determine the best path forward.
            </Oneline>
          </Line3Main>
          <PrivacyNote>
            We respect your privacy. Information submitted in this form will never be shared with any third party.
          </PrivacyNote>
        </SubContainer1Heading>
      </SubContainer1>
      <SubContainer2>
        <SubContainer2Box>
          <NameSection>
            <NameLabel>Name</NameLabel>
            <NameInput required type='text'></NameInput>
          </NameSection>
          <EmailSection>
            <EmailLabel>Email</EmailLabel>
            <EmailInput required type='text'></EmailInput>
          </EmailSection>
          <PhoneSection>
            <PhoneLabel>Phone #</PhoneLabel>
            <PhoneInput required type='text'></PhoneInput>
          </PhoneSection>
          <CompanySection>
            <CompanyLabel>Company</CompanyLabel>
            <CompanyInput required type='text'></CompanyInput>
          </CompanySection>
          <CountrySection>
            <CountryLabel>Country</CountryLabel>
            <CountrySelect></CountrySelect>
          </CountrySection>
          <QuestionCommentSection>
            <QuestionCommentLabel>Questions/Comments</QuestionCommentLabel>
            <QuestionCommentBox></QuestionCommentBox>
          </QuestionCommentSection>
          <SendRequestSection>
            <SendRequestBtn>Send request</SendRequestBtn>
          </SendRequestSection>
          </SubContainer2Box>    
      </SubContainer2>
    </MainContainer>
  );
};

export default Demo;
