import styled from 'styled-components';

export const MainContainer = styled.div`
  width:1024px;
  height: 680px;
  display: flex;  
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  @media screen and (max-width: 1024px) {
    width: 95%;
    height: auto;
    margin: 5% auto;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: auto;
    margin: 5% auto;
  }

  @media screen and (max-width: 512px) {
    width: 95%;
    height: auto;
    margin: 8% auto;
  }
`;

export const SubContainer1 = styled.div`
  width: 450px;
  height: 580px;
  display: inline-block;
  
  @media screen and (max-width: 1024px) {
    width: 47.5%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 47.5%;
    height: auto;
  }

  @media screen and (max-width: 512px) {
    width: 47.5%;
    height: auto;
  }

`;

export const SubContainer2 = styled.div`
  width: 450px;
  background-color: white;
  height: 580px;
  margin-left:50px;
  border: 5px solid #316C9C;

  @media screen and (max-width: 1024px) {
    width: 48%;
    height: auto;
    margin-left:auto;
  }

  @media screen and (max-width: 768px) {
    width: 48%;
    height: auto;
    margin-left:auto;
  }

  @media screen and (max-width: 512px) {
    width: 48%;
    height: auto;
    margin-left:auto;
  }
`;

export const SubContainer2Box = styled.div`


  @media screen and (max-width: 1024px) {
    width: 90%;
    height: auto;
    margin: auto auto;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    height: auto;
    margin: auto auto;
  }

  @media screen and (max-width: 512px) {
    width: 90%;
    height: auto;
    margin: auto auto;
  }
`;

export const SubContainer1Heading = styled.div`

`;

export const SubContainer1MinorMeading = styled.div`

`;

export const Heading1 = styled.div`
  color: #316c9c;
  font-size: 38px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 512px) {
    font-size: 18px;
  }
`;

export const DemoParagraph = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 512px) {
    font-size: 12px;
  }
`;

export const SubHeading = styled.div`
  margin-left: 34px;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const Line1Main = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  display: flex;
  margin-bottom: -20px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-bottom: -10px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-bottom: -10px;
  }
`;

export const Line2Main = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  display: flex;
  margin-bottom: -20px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-bottom: -10px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-bottom: -10px;
  }
`;

export const Line3Main = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  font-size: 22px;
  display: flex;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const Checkmark = styled.img`
  height: 22px;
  width: auto;
  margin-top: 22px;
  margin-right: 10px;

  @media screen and (max-width: 1024px) {
    height: 18px;
    width: auto;
    margin-top: 5%;
    margin-right: 10px;
  }

  @media screen and (max-width: 768px) {
    height: 16px;
    width: auto;
    margin-top: 5%;
    margin-right: 10px;
  }

  @media screen and (max-width: 512px) {
    height: 12px;
    width: auto;
    margin-top: 5%;
    margin-right: 10px;
  }
`;

export const Oneline = styled.p`

`;

export const PrivacyNote = styled.p`
  margin-left: 40px;
  text-indent: 20px;
  font-size: 15px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 512px) {
    font-size: 8px;
  }
`;

export const NameSection = styled.div`
  display: flex;
  flex-direction: column;  
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {
    width:94%
  }

  @media screen and (max-width: 768px) {
    width:92%
  }

  @media screen and (max-width: 512px) {
    width:91%
  }
`;

export const NameLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;
  margin-top: 10px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 0px;
  }
`;
export const EmailSection = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width:94%
  }

  @media screen and (max-width: 768px) {
    width:92%
  }

  @media screen and (max-width: 512px) {
    width:91%
  }
`;

export const EmailLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 0px;
  }
`;

export const PhoneSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {
    width:94%
  }

  @media screen and (max-width: 768px) {
    width:92%
  }

  @media screen and (max-width: 512px) {
    width:91%
  }
`;

export const PhoneLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 0px;
  }
`;

export const CompanySection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 1024px) {
    width:94%
  }

  @media screen and (max-width: 768px) {
    width:92%
  }

  @media screen and (max-width: 512px) {
    width:91%
  }
`;

export const CompanyLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 0px;
  }
`;

export const CountrySection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const CountryLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 5px;
  }
`;

export const QuestionCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const QuestionCommentLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: initial;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
    margin-left: initial;
    margin-bottom: 5px;
  }
`;

export const NameInput = styled.input`
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 8px;
  width: 380px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    height: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 12px;
    height: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    font-size: 10px;
    height: 8px;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const EmailInput = styled.input`
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 8px;
  width: 380px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    height: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 12px;
    height: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    font-size: 10px;
    height: 8px;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const PhoneInput = styled.input`
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 8px;
  width: 380px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    height: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 12px;
    height: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    font-size: 10px;
    height: 8px;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const CompanyInput = styled.input`
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 8px;
  width: 380px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    height: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 12px;
    height: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    font-size: 10px;
    height: 8px;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const CountrySelect = styled.select`
  padding: 8px;
  width: 400px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 6px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 4px;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    padding: 0px;
  }
`;

export const QuestionCommentBox = styled.textarea`
  min-height: 80px;
  width: 400px;
  margin: 0px auto;

  @media screen and (max-width: 1024px) {
    width: 98%;
    min-height: auto;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 768px) {
    width: 96%;
    min-height: auto;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 512px) {
    width: 96%;
    min-height: auto;
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }
`;


export const SendRequestSection = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 512px) {
    font-size: 10px;
  }
`;

export const SendRequestBtn = styled.div`
  margin-top: 10px;
  background: #fff;
  cursor: pointer;
  border-radius: 22px;
  text-decoration: none;
  outline: none;
  border: 1px solid #000000;
  text-decoration: none;
  color: #000000;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 140px;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ccc;
    color: #010606;
  }

  @media screen and (max-width: 1024px) {
    width: 40%;
    margin-bottom: 2%;
    margin-top: 2%;
    padding: 8px;
  }

  @media screen and (max-width: 768px) {
    width: 40%;
    margin-bottom: 2%;
    margin-top: 2%;
    padding: 6px;
  }

  @media screen and (max-width: 512px) {
    width: 40%;
    margin-bottom: 2%;
    margin-top: 2%;
    padding: 4px;
  }
`;